import React, {ReactElement, SyntheticEvent, useState} from "react";
import {EmployeeType} from "../../types/EmployeeType";
import {Utils} from "../../utils/Utils";
import {Box, Grid2, Tab} from "@mui/material";
import {Navbar} from "../Navbar";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {TabAdminCompany} from "./TabAdminCompany";
import {TabAdminContract} from "./TabAdminContract";

export function AdminIndex(props: any): ReactElement {
    const me: EmployeeType = props.me;

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    const [activeTab, setActiveTab] = useState<string>(
        Utils.hasRole("admin_company")
            ? '0'
            : (Utils.hasRole("admin_contract")
                ? '1'
                : '0'));

    return (<>
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Navbar me={me}/>

            <Grid2 container direction="column" alignItems="center" justifyContent="center">

                    <TabContext value={activeTab}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleChange}>
                                {Utils.hasRole("admin_company")
                                    ? <Tab label="Организации" value="0"/>
                                    : <></>
                                }
                                {Utils.hasRole("admin_contract")
                                    ? <Tab label="Договора" value="1"/>
                                    : <></>
                                }
                            </TabList>
                        </Box>
                        {Utils.hasRole("admin_company") && activeTab === '0' ? <TabAdminCompany value={activeTab} me={me}/> : <></>}
                        {Utils.hasRole("admin_contract") && activeTab === '1' ? <TabAdminContract value={activeTab} me={me}/> : <></>}
                    </TabContext>

            </Grid2>
        </Grid2>
    </>)

}