import {ReactElement} from "react";
import {Container, Grid2} from "@mui/material";
import {Navbar} from "./Navbar";
import {EmployeeType} from "../types/EmployeeType";
import { Utils } from "../utils/Utils";
import {Navigate} from "react-router-dom";

export function Main(props: any): ReactElement {
    const me = props.me as EmployeeType;

    if (Utils.hasRole("road")) {
        return <Navigate to={"/road"}/>
    }
    if (Utils.hasRole("water")) {
        return <Navigate to={"/water"}/>
    }
    if (Utils.hasRole("rail")) {
        return <Navigate to={"/rail"}/>
    }
    if (Utils.hasRole("air")) {
        return <Navigate to={"/air"}/>
    }
    if (Utils.hasRole("admin")) {
        return <Navigate to={"/admin"}/>
    }

    return (<>
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Navbar me={me}/>

            <Container>
                Main
            </Container>
        </Grid2>
    </>)
}