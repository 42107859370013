import {jwtDecode} from 'jwt-decode';
import {CountryType} from "./types/CountryType";
import {RequestType} from "./types/RequestType";
import {OtbJwt} from "./types/OtbJwt";
import {saveAs} from "file-saver";
import {FileType} from "./types/FileType";
import {PassportType} from "./types/PassportType";
import {RegionType} from "./types/RegionType";
import {DistrictType} from "./types/DistrictType";
import {CompanyType} from "./types/CompanyType";
import {DKHAType} from "./types/DKHAType";
import {
    ContractObjectType,
    ContractType,
    FlatContractDataType,
    FlatContractType,
    NewContractType
} from "./types/ContractType";
import {EmployeeType} from "./types/EmployeeType";
import {VesselType} from "./types/VesselType";
import {NotificationType} from "./types/NotificationType";
import {DogovorType} from "./types/DogovorType";
import {RequestBlockType} from "./types/RequestBlockType";
import {WaterPublicType} from "./types/WaterPublicType";
import {Utils} from "./utils/Utils";

export class REST {
    public static BASE: String = process.env.REACT_APP_BASE ?? "";

    public static login(username: string, password: string): Promise<OtbJwt> {
        return fetch(this.BASE + "/api/auth/login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username: username, password: password})
        })
            .then((response) => {
                if (response.status === 401) {
                    return response.json()
                        .then((e) => {
                            throw new Error(e['message'])
                        })
                        .catch((e: Error) => {
                            throw new Error(e.message)
                        });
                }
                return response.json();
            })
            .then((token) => {
                const jwt = jwtDecode<OtbJwt>(token?.access_token);
                jwt.user.cert = (jwt.user.cert === undefined ? null : jwt.user.cert);
                sessionStorage.setItem("me", JSON.stringify(jwt.user));
                sessionStorage.setItem("jwt", token?.access_token);
                //localStorage.setItem('token', token);
                return jwt;
            });
    }

    public static logout(): void {
        fetch(this.BASE + "/api/auth/logout", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .catch((error) => {
                console.error(error);
                return [];
            })
            .finally(() => {
                sessionStorage.removeItem("me");
                sessionStorage.removeItem("jwt");
                window.location.href = '/';
            });
    }

    public static GET<T>(uri: string): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';//throw new Error();
                } else if(response.status === 404) {
                    throw new Error('Не найдено');
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static POST<T>(uri: string, body: T): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';//throw new Error();
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static PUT<T>(uri: string, body: T): Promise<T> {
        return fetch(this.BASE + uri, {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';//throw new Error();
                }
                return response.json();
            })
            .then((data: Promise<T>) => {
                return data;
            });
    }

    public static DELETE(uri: string): Promise<any> {
        return fetch(this.BASE + uri, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response;
            });
    }


    public static getCountry(): Promise<CountryType[]> {
        return REST.GET("/api/util/cache/countries");
    }

    public static getRegion(): Promise<RegionType[]> {
        return REST.GET("/api/util/cache/regions");
    }

    public static getDistrictByRegion(rid: bigint): Promise<DistrictType[]> {
        return REST.GET("/api/cache/district/" + rid);
    }

    public static getRequest(type: String): Promise<RequestType[]> {
        return REST.GET("/api/water/requests?type=" + type);
    }

    public static getRequestById(id: bigint): Promise<RequestType> {
        return REST.GET("/api/water/requests/" + id);
    }

    public static setRequestStatus(id: bigint, status: number): Promise<RequestType> {
        return fetch(this.BASE + "/api/water/requests/status", {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({id: id, status: status})
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestType>) => {
                return data;
            });
    }

    public static download(f: FileType): void {
        fetch(this.BASE + "/api/file/download?fid=" + f.id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, f.name);
            });
    }

    public static downloadReport(r: RequestType | null, format: string): void {
        const uid = 1;
        fetch(this.BASE + "/api/report/download." + format + "?uid=" + uid + "&start=0&finish=0&req=" + r?.id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, "Заявка №" + r?.id + " от " +  Utils.formatDateTime(r?.date) + "." + format);
            });
    }

    public static downloadDailyReport(cid: bigint, start: string, finish: string, format: string): void {
        const uid = 2;
        fetch(this.BASE + "/api/report/download." + format + "?uid=" + uid + "&req=" + cid + "&start=" + start + "&finish=" + finish, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, "Справка по контракту." + format);
            });
    }

    public static downloadZipReport(cid: bigint, contractNumber: string, start: number, finish: number): Promise<any> {
        return fetch(this.BASE + "/api/package/contract/" + cid + ".zip?start=" + start + "&finish=" + finish, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, contractNumber + ".zip");
            });
    }

    /*public static uploadFile(form: FormData): Promise<FileType> {
        return fetch(this.BASE + "/api/file/upload", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: form
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<FileType>) => {
                return data;
            });
    }*/

    public static createRequest(fields: any): Promise<RequestType> {
        return REST.POST("/api/water/requests", fields);
        /*return fetch("http://localhost:8080/api/water/requests", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify(fields, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((req) => {
                return req;
            });*/
    }

    public static createContract(contract: NewContractType): Promise<ContractType> {
        return fetch(this.BASE + "/api/contract", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify(contract, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((req) => {
                return req;
            });
    }

    public static getPassports(): Promise<PassportType[]> {
        return REST.GET("/api/water/passports");
    }

    public static getCompanyByInn(inn: bigint, useCache: boolean): Promise<CompanyType> {
        return REST.GET("/api/util/" + (useCache ? "cache/" : "" )+ "companies/" + inn.toString());
    }

    public static getVesselByIMO(imo: number): Promise<VesselType[]> {
        return REST.GET("/api/util/cache/vessels/" + imo);
    }

    public static getDKHAByNumber(number: number): Promise<DKHAType | null> {
        return REST.GET("/api/cache/dkha/" + number);
    }

    public static getRoadOperatorContractList(): Promise<ContractType[]> {
        return REST.GET("/api/contract/operator");
    }

    public static getRoadExecutorContractList(): Promise<ContractType[]> {
        return REST.GET("/api/contract/executor");
    }

    public static getRoadCustomerContractList(): Promise<ContractType[]> {
        return REST.GET("/api/contract/customer");
    }

    public static getRoadContractById(id: bigint): Promise<FlatContractType> {
        return REST.GET("/api/contract/" + id);
    }

    public static getContractDataById(cid: bigint, dkha: number): Promise<FlatContractDataType[]> {
        return REST.GET("/api/contract/" + cid + "/" + dkha);
    }

    public static getWaterContractList(type: string): Promise<ContractType[]> {
        return REST.GET("/api/water/contracts?type=" + type);
    }

    public static getWaterContractById(id: bigint): Promise<ContractType> {
        return REST.GET("/api/water/contracts/" + id);
    }

    public static getWaterContractObjectById(id: bigint): Promise<ContractObjectType> {
        return REST.GET("/api/water/contracts/objects/" + id);
    }

    public static createWaterObjectReport(form: FormData): Promise<bigint> {
        return fetch(this.BASE + "/api/water/contracts/objects/reports", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: form
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<bigint>) => {
                return data;
            });
    }

    public static downloadWaterContractFile(id: bigint): Promise<any> {
        var fileName: string = 'unknown-file.bin';
        return fetch(this.BASE + "/api/water/contracts/objects/files/" + id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                const header = response.headers.get('Content-Disposition');
                const parts = header!.split(';');
                fileName = decodeURI(parts[1].split("*=UTF-8''")[1]).replaceAll('+', ' ');
                return response.blob();
            })
            .then((data) => {
                saveAs(data, fileName!);
            });
    }

    public static uploadDailyReport(form: FormData): Promise<bigint> {
        return fetch(this.BASE + "/api/contract/upload", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: form
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<bigint>) => {
                return data;
            });
    }

    public static getSubordinates(): Promise<EmployeeType[]> {
        return REST.GET("/api/util/users/subordinates");
    }

    public static changeFinalExecutor(payload: any): Promise<RequestType> {
        return REST.PUT("/api/water/requests/change-executor", payload);
    }

    public static changePassword(newPassword: string): any {
        return fetch(this.BASE + "/api/util/users/password", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify({password: newPassword})
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
            });
    }

    public static getCompanies(): Promise<CompanyType[]> {
        return REST.GET("/api/admin/companies");
    }

    public static getNotifications(type: string): Promise<NotificationType[]> {
        return REST.GET("/api/water/notifications?type=" + type);
    }

    public static registerCompany(company: any): Promise<CompanyType> {
        return REST.POST("/api/admin/companies", company);
    }

    public static getDogovors(): Promise<DogovorType[]> {
        return REST.GET("/api/admin/contracts");
    }

    public static addDogovor(dogovor: any): Promise<DogovorType> {
        return REST.POST("/api/admin/contracts", dogovor);
    }

    public static extendDogovor(id: bigint): Promise<DogovorType> {
        return REST.GET("/api/admin/contracts/extend?id=" + id.toString());
    }

    public static getFileByType(type: string): Promise<FileType[]> {
        return REST.GET("/api/file/?type=" + type);
    }

    public static newRequestBlock(requestBlock: FormData): Promise<RequestBlockType> {
        return fetch(this.BASE + "/api/water/requests/block", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: requestBlock
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestBlockType>) => {
                return data;
            });
    }

    public static getWaterPublic(id: string): Promise<WaterPublicType> {
        return REST.GET("/api/water/public?id=" + id);
    }

    public static getWaterExecutors(): Promise<CompanyType[]> {
        return REST.GET("/api/water/executors")
    }
}
