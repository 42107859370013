import React, {ReactElement, SyntheticEvent, useState} from "react";
import {Utils} from "../../utils/Utils";
import {EmployeeType} from "../../types/EmployeeType";
import {Navbar} from "../Navbar";
import {Box, Grid2, Tab} from "@mui/material";
import {TabContext, TabList} from "@mui/lab";
import OutputIcon from '@mui/icons-material/Output';
import InputIcon from '@mui/icons-material/Input';
import ArticleIcon from '@mui/icons-material/Article';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import BarChartIcon from '@mui/icons-material/BarChart';
import {TabAnalytics} from "./TabAnalytics";
import {TabOutNotifications} from "./TabOutNotifications";
import {TabDocuments} from "./TabDocuments";
import {TabPassports} from "./TabPassports";
import {TabContract} from "./TabContract";
import { TabInRequests } from "./TabInRequests";
import {TabOutRequests} from "./TabOutRequests";

export function WaterIndex(props: any): ReactElement {
    const me: EmployeeType = props.me;

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    const [activeTab, setActiveTab] = useState<string>(
        Utils.hasRole("water_sti_request")
            ? '0'
            : (Utils.hasRole("water_sti")
                ? '1'
                : (Utils.hasRole("water_ptb_request")
                    ? '2'
                    : (Utils.hasRole("water_passport")
                        ? '3'
                        : (Utils.hasRole("water_out_contract")
                            ? '4'
                            : (Utils.hasRole("water_in_contract")
                                ? '5'
                                : '0'))))));

    return (<>
        <Grid2
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Navbar me={me}/>

            <Grid2 container direction="column" alignItems="center" justifyContent="center">
                <TabContext value={activeTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange}>
                            {Utils.hasRole("water_sti_request")
                                ? <Tab icon={<OutputIcon/>} label="Исходящие заявки" value="0"/>
                                : ''
                            }
                            {Utils.hasRole("water_sti")
                                ? <Tab icon={<OutputIcon/>} label="Исходящие уведомления" value="1"/>
                                : ''
                            }
                            {Utils.hasRole("water_ptb_request")
                                ? <Tab icon={<InputIcon/>} label="Входящие заявки" value="2"/>
                                : ''
                            }
                            {Utils.hasRole("water_passport")
                                ? <Tab icon={<ContactPageIcon/>} label="Паспорта ОТБ ТС" value="3"/>
                                : ''
                            }
                            {Utils.hasRole('water_out_contract')
                                ? <Tab icon={<OutputIcon/>} label="Исходящие контракты" value="4"/>
                                : ''
                            }
                            {Utils.hasRole('water_in_contract')
                                ? <Tab icon={<InputIcon/>} label="Входящие контракты" value="5"/>
                                : ''
                            }
                            {Utils.hasRole("water_ptb")
                                ? <Tab icon={<ArticleIcon/>} label="Документы" value="6"/>
                                : ''
                            }
                            {Utils.hasRole("water_analytics")
                                ? <Tab icon={<BarChartIcon/>} label="Аналитика" value="7"/>
                                : ''
                            }
                        </TabList>
                    </Box>

                    {Utils.hasRole("water_sti_request") && activeTab === '0' ? <TabOutRequests value={activeTab} me={me}/> : ''}
                    {Utils.hasRole("water_sti") && activeTab === '1' ? <TabOutNotifications value={activeTab} me={me}/> : ''}
                    {Utils.hasRole("water_ptb_request") && activeTab === '2' ? <TabInRequests value={activeTab} me={me}/> : ''}
                    {Utils.hasRole("water_passport") && activeTab === '3' ? <TabPassports value={activeTab} me={me}/> : ''}
                    {Utils.hasRole("water_out_contract") && activeTab === '4' ?
                        <TabContract value={activeTab} me={me} type={"customer"}/> : ''}
                    {Utils.hasRole("water_in_contract") && activeTab === '5' ?
                        <TabContract value={activeTab} me={me} type={"executor"}/> : ''}
                    {Utils.hasRole("water_ptb") && activeTab === '6' ? <TabDocuments value={activeTab} me={me}/> : ''}
                    {Utils.hasRole("water_analytics") && activeTab === '7' ? <TabAnalytics value={activeTab} me={me}/> : ''}
                </TabContext>
            </Grid2>
        </Grid2>
    </>);
}