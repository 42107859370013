import {ChangeEvent, Dispatch, ReactElement, SetStateAction, useRef, useState} from "react";
import {REST} from "../../../REST";
import {EmployeeType} from "../../../types/EmployeeType";
import {Utils} from "../../../utils/Utils";
import {ContractReportType} from "../../../types/ContractType";
import {PopupType} from "../../../Popup";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    ListItemIcon,
    Typography,
    Box, SelectChangeEvent
} from "@mui/material";
import PhotoIcon from '@mui/icons-material/Photo';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {DropzoneArea} from "mui-file-dropzone";

export function ObjectReportModal(props: any): ReactElement {
    const minFileCount: number = 1;
    const [popup, setPopup] = useState<PopupType | null>(null);

    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
    const objectId = props.objectId as bigint;
    const objectName = props.objectName as string;
    const [open, setOpen] = props.show as [boolean, Dispatch<SetStateAction<boolean>>];
    const [currentReport, setCurrentReport] = props.report as [ContractReportType | null, Dispatch<SetStateAction<ContractReportType | null>>];
    const [canSave, setCanSave] = useState<boolean>(true);//TODO: check is off
    const [loading, setLoading] = useState<boolean>(false);

    const [timeOfCheck, setTimeOfCheck] = useState<string>(Utils.formatTime(new Date().toString()));
    const [checkType, setCheckType] = useState<string>('Д');
    const [violationCount, setViolationCount] = useState<string>('0');
    const [violationObjectsCount, setViolationObjectsCount] = useState<string>('0');
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);
    const [description, setDescription] = useState<string>('Не выявлено');

    const handleClose = () => {
        setOpen(false);
    }

    if (!open) {
        return (<></>);
    }

    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
        if(e.target.name === 'description') {
            setDescription(e.target.value);
        } else if(e.target.name === 'timeOfCheck') {
            setTimeOfCheck(e.target.value);
        }

        /*setCanSave(
            (description.current?.value.length ?? 0) > 0 &&
            uploadFiles.length >= minFileCount
        );*/
    }

    const sendReport = () => {
        setLoading(true);
        let newReport = new FormData();

        newReport.append("coid", objectId.toString());
        newReport.append("time", timeOfCheck);
        newReport.append("type", checkType!);
        newReport.append("subject", violationCount);
        newReport.append("object", violationObjectsCount);
        newReport.append("description", description);

        for (let i = 0; i < uploadFiles.length; i++) {
            newReport.append("files", uploadFiles.at(i)!);
        }
        REST.createWaterObjectReport(newReport).then(r => {
            setLoading(false);
            handleClose();
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка создания отчета', message: error.message});
        });
    }

    return (<Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{currentReport
            ? "Отчет от " + Utils.formatDateTime(currentReport?.date)
            : "Новый отчет"
        }</DialogTitle>
        <DialogContent dividers>
            <DialogContentText>
            </DialogContentText>

            <FormControl fullWidth sx={{mb: 2}} disabled>
                <InputLabel htmlFor={'object'}>Объект</InputLabel>
                <OutlinedInput id={'object'} label={"Объект"} value={objectName}/>
            </FormControl>

            <FormControl fullWidth sx={{mb: 2}} disabled>
                <InputLabel htmlFor={'employee'}>ФИО работника (должность)</InputLabel>
                <OutlinedInput id={'employee'} name={"employee"} label={"ФИО работника (должность)"}
                               value={(currentReport ? currentReport?.employee.fio : me.fio) + ' (Контролёр)'}/>
            </FormControl>

            <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                <FormControl fullWidth disabled>
                    <InputLabel htmlFor={'date'}>Дата проверки</InputLabel>
                    <OutlinedInput id={'date'} name={"date"} label={"Дата проверки"}
                                   value={Utils.formatDate(currentReport ? currentReport.date : new Date().toString())}/>
                </FormControl>
                <FormControl fullWidth disabled={currentReport !== null}>
                    <InputLabel htmlFor={'timeOfCheck'}>Время проверки</InputLabel>
                    <OutlinedInput id={'timeOfCheck'} name={"timeOfCheck"} label={"Время проверки"}
                                   defaultValue={(currentReport ? Utils.formatTime(currentReport.date) : Utils.formatTime(new Date().toString()))}
                                   onChange={handleOnChange}/>
                </FormControl>
            </Stack>

            <FormControl fullWidth sx={{mb: 2}} disabled={currentReport !== null}>
                <InputLabel id="checkType">Тип проверки</InputLabel>
                <Select
                    labelId="checkType"
                    value={currentReport?.type}
                    name={"checkType"}
                    label="Тип проверки"
                    defaultValue={"Д"}
                    onChange={handleOnChange}
                >
                    <MenuItem value={"Д"}>Проведение досмотра (Д)</MenuItem>
                    <MenuItem value={"ДД"}>Проведение дополнительного досмотра (ДД)</MenuItem>
                    <MenuItem value={"ПД"}>Проведение повторного досмотра (ПД)</MenuItem>
                    <MenuItem value={"Н"}>Проведение наблюдения (Н)</MenuItem>
                    <MenuItem value={"С"}>Проведение собеседования (С)</MenuItem>
                    <MenuItem value={"П"}>Патрульный обход (осмотр) (П)</MenuItem>
                </Select>
            </FormControl>

            <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                <FormControl fullWidth disabled={currentReport !== null}>
                    <InputLabel htmlFor={'subject'}>Выявленные нарушители</InputLabel>
                    <OutlinedInput id={'subject'} name={"violationCount"} label={"Выявленные нарушители"} type={"number"}
                                   defaultValue={(currentReport ? currentReport.subject : 0)}
                                   inputProps={{min: 0}}
                                   onChange={handleOnChange}/>
                </FormControl>
                <FormControl fullWidth disabled={currentReport !== null}>
                    <InputLabel htmlFor={'object'}>Выявленные предметы</InputLabel>
                    <OutlinedInput id={'object'} name={"violationObjectsCount"} label={"Выявленные предметы"} type={"number"}
                                   defaultValue={(currentReport ? currentReport.object : 0)}
                                   inputProps={{min: 0}}
                                   onChange={handleOnChange}/>
                </FormControl>
            </Stack>

            <FormControl fullWidth sx={{mb: 2}} disabled={currentReport !== null}>
                <InputLabel htmlFor={'description'}>Описание выявленных недостатов, дефектов, тревожных
                    ситуаций</InputLabel>
                <OutlinedInput id={'description'} name={"description"} multiline rows={4}
                               label={"Описание выявленных недостатов, дефектов, тревожных ситуаций"}
                               onChange={handleOnChange}
                               defaultValue={currentReport ? currentReport?.description : "Не выявлено"}/>
            </FormControl>

            {Utils.hasRole("water_out_contract")
                ? <></>
                : currentReport?.files.length === 0
                    ? <Typography variant={"body1"} sx={{justifySelf: "center"}}>Нет прикрепленных фото</Typography>
                    : <>{currentReport
                        ? <><Typography variant={"body1"}>Прикрепленные фото:</Typography>
                            <List>
                                {currentReport?.files.map(f =>
                                    <ListItem disablePadding key={f.id}>
                                        <ListItemButton onClick={() => REST.downloadWaterContractFile(f.id)}>
                                            <ListItemIcon><PhotoIcon/></ListItemIcon>
                                            <ListItemText primary={f.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </List>
                        </>
                        : <></>
                    }
                        {!currentReport
                            ? <>
                                <DropzoneArea
                                    maxFileSize={1000000000}
                                    showAlerts={false}
                                    acceptedFiles={['image/*']}
                                    showFileNames={true}
                                    showPreviewsInDropzone={true}
                                    Icon={AddPhotoAlternateIcon}
                                    dropzoneText={"Перетащите фото или кликните для выбора"}
                                    onChange={(files) => setUploadFiles(files)}
                                />
                            </>
                            : <></>
                        }
                    </>
            }

        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} disabled onClick={() => alert('Будет произведена отправка в ЕГИС ОТБ')}>Отправить
                в ЕГИС ОТБ</Button>
            {/*isCustomer ? <div className="btn btn-primary disabled"
                                           onClick={() => REST.downloadDailyReport(BigInt(cdi.current!.value), dateOfCheck.current!.innerText, dateOfCheck.current!.innerText, 'docx')}>
                                <FontAwesomeIcon
                                    icon={faDownload}/>&nbsp;Скачать
                            </div>
                            : ""
                    */}
            <Button variant="contained" color={"error"} onClick={() => handleClose()}>Отмена</Button>
            {!currentReport
                ? <Button variant="contained" color={"success"} loading={loading}
                          disabled={!canSave || !Utils.hasRole("water_in_contract") || loading}
                          onClick={() => sendReport()}>Отправить</Button>
                : <></>
            }
        </DialogActions>
    </Dialog>);
}