import React, {ReactElement, useEffect, useState} from "react";
import {EmployeeType} from "../../types/EmployeeType";
import {useParams} from "react-router-dom";
import {REST} from "../../REST";
import {ContractObjectType, ContractReportType} from "../../types/ContractType";
import {Utils} from "../../utils/Utils";
import {Backdrop, Button, CircularProgress, Container, Divider, Grid2, Typography} from "@mui/material";
import {Navbar} from "../Navbar";
import {Popup, PopupType} from "../../Popup";
import {ObjectReportModal} from "./modal/ObjectReportModal";

export function WaterContractObjectPage(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
    const {id} = useParams<string>();
    const [contractObject, setContractObject] = useState<ContractObjectType>();
    const [currentReport, setCurrentReport] = useState<ContractReportType | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        REST.getWaterContractObjectById(BigInt(id!)).then(co => {
            setContractObject(co);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, [id]);

    if (loading) {
        return (<>
            <Grid2 container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Navbar me={me}/>
                <Container maxWidth="lg">
                    <Backdrop sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})} open={true}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </Container>
            </Grid2>
        </>);
    }

    return (<>
            <Grid2 container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Navbar me={me}/>
                <Container maxWidth="lg" sx={{mb: 1}}>

                    <Typography variant="h5" gutterBottom
                                sx={{mt: 1, justifySelf: "center"}}>{"Объект \"" + contractObject!.name + "\""}</Typography>
                    <Divider sx={{mb: 1}}/>

                    <Grid2 container spacing={1} columns={8} alignItems="center" justifyContent="center">
                        {Utils.hasRole("water_in_contract")
                            ? <Button variant="contained" color={"primary"} key={0}
                                      onClick={() => {
                                          setCurrentReport(null);
                                          setShowModal(true);
                                      }}>Добавить отчет</Button>
                            : <></>
                        }
                        {contractObject!.reports.map(cr =>
                            <Button variant="contained" color={"success"} key={cr.id} onClick={() => {
                                setCurrentReport(cr);
                                setShowModal(true);
                            }}>{Utils.formatDateTime(cr.date)}</Button>
                        )}
                    </Grid2>

                </Container>
            </Grid2>

            <ObjectReportModal show={[showModal, setShowModal]} report={[currentReport, setCurrentReport]}
                               objectName={contractObject!.name} objectId={contractObject!.id} me={me}/>

            <Popup popup={[popup, setPopup]}/>
        </>
    )
}
