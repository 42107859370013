import {ReactElement} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export function NotFound(props: any): ReactElement {
    const navigate = useNavigate();

    return (<Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: 'rgb(2, 136, 209)',
        }}
        >
            <Typography variant="h1" style={{color: 'white'}}>404</Typography>
            <Typography variant="h6" style={{color: 'white'}}>Страница не найдена</Typography>
            <Button variant="contained" color="error" sx={{mt: 2}} onClick={() => navigate('/')}>На главную</Button>
        </Box>)
}