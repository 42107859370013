import React, {ReactElement} from "react";
import {
    Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem,
    ListItemButton,
    ListItemText, Stack, Typography
} from "@mui/material";
import {Popup} from "../Popup";

export function LegalPage(props: any): ReactElement {
    return (<>
        <Dialog open={true} sx={{backgroundColor: 'rgb(2, 136, 209)'}} slots={{backdrop: 'span'}}>
            <DialogTitle>
                <Stack direction={"column"} spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant={"h5"} sx={{textAlign: "center"}}>Правовая информация</Typography>
                    <Typography variant={"subtitle2"}
                                sx={{textAlign: "center"}}>{"Сервисы " + process.env.REACT_APP_TITLE + " позволяют вам проводить досмотры и осмотры."}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <List sx={{width: '100%'}}>
                    <ListItem alignItems="flex-start">
                        <ListItemButton>
                            <ListItemText
                                primary={"Правила пользования сайтом"}
                                secondary={"Ознакомьтесь с правилами для пользователей " + process.env.REACT_APP_TITLE + "."}
                            />
                        </ListItemButton>
                    </ListItem>

                    <Divider/>

                    <ListItem alignItems="flex-start">
                        <ListItemButton>
                            <ListItemText
                                primary={"Лицензионное соглашение"}
                                secondary={"Узнайте, на каких условиях пользователям предоставляется право использования " + process.env.REACT_APP_TITLE + "."}
                            />
                        </ListItemButton>
                    </ListItem>

                    <Divider/>

                    <ListItem alignItems="flex-start">
                        <ListItemButton>
                            <ListItemText
                                primary={"Оферта о заключении договора"}
                                secondary={"Условия оказания услуг, запреты, ограничения."}
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    </>);
}