import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {ContractType} from "../../types/ContractType";
import {Utils} from "../../utils/Utils";
import {EmployeeType} from "../../types/EmployeeType";
import {Popup, PopupType} from "../../Popup";
import {TabPanel} from "@mui/lab";
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { ContractModal } from "./modal/ContractModal";

export function TabContract(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;
    const me = props.me as EmployeeType;
    const type = props.type as string;
    const [contracts, setContracts] = useState<ContractType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showNewModal, setShowNewModal] = useState<boolean>(false);
    const [currentContract, setCurrentContract] = useState<ContractType>();

    useEffect(() => {
        REST.getWaterContractList(type).then((c: ContractType[]) => {
            setContracts(c);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, [type]);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>
            {Utils.hasRole("water_out_contract")
                ? <Button variant="contained" disabled={loading} onClick={() => setShowNewModal(true)}>Создать
                    контракт</Button>
                : <></>}

                <List>
                    {loading
                        ? <ListItem><IconButton loading><HourglassBottomIcon/></IconButton></ListItem>
                        : contracts.length === 0
                            ? <ListItem disablePadding>Нет контрактов</ListItem>
                            : contracts.map((c: ContractType) =>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setCurrentContract(c);
                                        setShowModal(true);
                                    }}>
                                        <ListItemText
                                            primary={"Контракт №" + c.number + " от " + Utils.formatDate(c.date)}/>
                                    </ListItemButton>
                                </ListItem>
                            )}
                </List>

        </TabPanel>

        <ContractModal show={[showModal, setShowModal]} current={[currentContract, setCurrentContract]}/>

        <Popup popup={[popup, setPopup]}/>
    </>);
}

/*
{Utils.hasRole("water_out_contract")
                ? <NewContractModal show={[showModal, setShowModal]} setContracts={setContracts}
                                    current={[currentContract, setCurrentContract]}/>
                : <></>
            }

 */
