import {AppBar, Avatar, Container, IconButton, Box, Toolbar, MenuItem, Menu, Button, Typography} from "@mui/material";
import {ReactElement, useState} from "react";
import {useNavigate} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileMenu from "./ProfileMenu";
import {EmployeeType} from "../types/EmployeeType";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TrainIcon from '@mui/icons-material/Train';
import FlightIcon from '@mui/icons-material/Flight';
import AnchorIcon from '@mui/icons-material/Anchor';
import SettingsIcon from '@mui/icons-material/Settings';
import {Utils} from "../utils/Utils";

export function Navbar(props: any): ReactElement {
    const me = props.me as EmployeeType;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (<>
        <AppBar>
            <Container fixed>
                <Toolbar>
                    <Box>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2, display: {md: 'none'}}}
                            onClick={handleClick}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id="menu"
                            open={anchorEl !== null}
                            onClose={handleClose}
                            onClick={handleClose}
                        >
                            <MenuItem key={'road'} onClick={() => navigate('/road')}
                                      disabled={!Utils.hasRole("road")}><LocalShippingIcon/>&nbsp;АВТОДОР</MenuItem>
                            <MenuItem key={'water'} onClick={() => navigate('/water')}
                                      disabled={!Utils.hasRole("water")}><AnchorIcon/>&nbsp;МОРРЕЧФЛОТ</MenuItem>
                            <MenuItem key={'rail'} onClick={() => navigate('/rail')}
                                      disabled={!Utils.hasRole("rail")}><TrainIcon/>&nbsp;ЖЕЛДОР</MenuItem>
                            <MenuItem key={'air'} onClick={() => navigate('/air')}
                                      disabled={!Utils.hasRole("air")}><FlightIcon/>&nbsp;АВИАЦИЯ</MenuItem>
                            {Utils.hasRole("admin")
                                ? <MenuItem key={'admin'}
                                            onClick={() => navigate('/admin')}><SettingsIcon/>&nbsp;АДМИНИСТРИРОВАНИЕ</MenuItem>
                                : ""
                            }
                        </Menu>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button key={'index'} sx={{my: 2, color: 'white', textTransform: 'none'}}
                                onClick={() => navigate('/')}>
                            <Typography variant="subtitle1">{me.company.name} (ИНН: {me.company.inn})</Typography>
                        </Button>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button key={'road'} sx={{my: 2, color: 'white'}} disabled={!Utils.hasRole("road")}
                                onClick={() => navigate('/road')} startIcon={<LocalShippingIcon/>}>Автодор</Button>
                        <Button key={'water'} sx={{my: 2, color: 'white'}} disabled={!Utils.hasRole("water")}
                                onClick={() => navigate('/water')} startIcon={<AnchorIcon/>}>Морречфлот</Button>
                        <Button key={'rail'} sx={{my: 2, color: 'white'}} disabled={!Utils.hasRole("rail")}
                                onClick={() => navigate('/rail')} startIcon={<TrainIcon/>}>Желдор</Button>
                        <Button key={'air'} sx={{my: 2, color: 'white'}} disabled={!Utils.hasRole("air")}
                                onClick={() => navigate('/air')} startIcon={<FlightIcon/>}>Авиация</Button>
                        {Utils.hasRole("admin")
                            ? <Button key={'admin'} sx={{my: 2, color: 'white'}}
                                      onClick={() => navigate('/admin')}
                                      startIcon={<SettingsIcon/>}>Администрирование</Button>
                            : <></>
                        }
                    </Box>

                    <Box sx={{flexGrow: 1, display: {md: 'none'}}}/>
                    <Box>

                        <IconButton
                            onClick={handleOpenUserMenu}
                            size="small"
                            sx={{ml: 2}}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar src={process.env.REACT_APP_BASE + '/api/user/' + me.id + '/avatar'}/>
                        </IconButton>

                        <ProfileMenu anchor={[anchorElUser, setAnchorElUser]} me={me}/>

                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        <Toolbar sx={{mb: 1}}/>
    </>);
}