import {
    ChangeEvent,
    ChangeEventHandler,
    Dispatch,
    FormEvent,
    ReactElement,
    SetStateAction,
    useEffect,
    useState
} from "react";
import {CountryType} from "../../../types/CountryType";
import {REST} from "../../../REST";
import {RequestType} from "../../../types/RequestType";
import {RegionType} from "../../../types/RegionType";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment,
    InputLabel, MenuItem,
    OutlinedInput, Select, SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import {PopupType} from "../../../Popup";
import {CompanyType} from "../../../types/CompanyType";

export function NewRequestModal(props: any): ReactElement {
    const [popup, setPopup] = props.popup as [(PopupType | null), Dispatch<SetStateAction<PopupType | null>>];

    const [open, setOpen] = props.show as [boolean, Dispatch<SetStateAction<boolean>>];
    //const [currentRequest, setCurrentRequest] = props.currentRequestState;
    const [countries, setCountries] = useState<CountryType[]>([]);
    const [regions, setRegions] = useState<RegionType[]>([]);
    const [executors, setExecutors] = useState<CompanyType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [canSave, setCanSave] = useState<boolean>(true);//FIXME: false

    const [nameCargo, setNameCargo] = useState<string>('');
    const [cargoError, setCargoError] = useState<string | null>(null);
    const [nameConsignee, setNameConsignee] = useState<string>('');
    const [consigneeError, setConsigneeError] = useState<string | null>(null);
    const [nameOperator, setNameOperator] = useState<string>('');
    const [operatorError, setOperatorError] = useState<string | null>(null);
    const [nameMaster, setNameMaster] = useState<string>('');
    const [masterError, setMasterError] = useState<string | null>(null);
    const [contact, setContact] = useState<string>('');
    const [imonumber, setImonumber] = useState<string>('');
    const [otbname, setOtbname] = useState<string>('');
    const [registrynumber, setRegistrynumber] = useState<string>('');
    const [reestrnumber, setReestrnumber] = useState<string>('');
    const [inport, setInport] = useState<string>('');
    const [fromport, setFromport] = useState<string>('');
    const [outport, setOutport] = useState<string>('');
    const [target, setTarget] = useState<string>('');
    const [place, setPlace] = useState<string>('');
    const [cargo, setCargo] = useState<string>('');
    const [count, setCount] = useState<string>('');
    const [indatetime, setIndatetime] = useState<string>('');
    const [flag, setFlag] = useState<string>('132');
    const [incountry, setIncountry] = useState<string>('132');
    const [fromcountry, setFromcountry] = useState<string>('132');
    const [outcountry, setOutcountry] = useState<string>('132');
    const [startloadtime, setStartloadtime] = useState<string>('');
    const [endloadtime, setEndloadtime] = useState<string>('');
    const [addressobject, setAddressobject] = useState<string>('');
    const [addressobjectregion, setAddressobjectregion] = useState<string>('');
    const [contactoperator, setContactoperator] = useState<string>('');
    const [contactmaster, setContactmaster] = useState<string>('');
    const [contactcargo, setContactcargo] = useState<string>('');
    const [executor, setExecutor] = useState<string>('');
    const [needOperations, setNeedOperations] = useState<number>(0);

    const [inncargo, setInncargo] = useState<string>('');
    const [innoperator, setInnoperator] = useState<string>('');
    const [innmaster, setInnmaster] = useState<string>('');
    const [innconsignee, setInnconsignee] = useState<string>('');

    useEffect(() => {
        REST.getCountry().then((c: CountryType[]) => {
            setCountries(c);
            REST.getRegion().then((r: RegionType[]) => {
                setRegions(r);
                REST.getWaterExecutors().then((ee: CompanyType[]) => {
                    setExecutors(ee);
                    setLoading(false);
                    setCanSave(ee.length > 0);
                }).catch((error: Error) => {
                    setPopup({title: 'Ошибка получения исполнителей', message: error.message});
                    setCanSave(false);
                })
            }).catch((error: Error) => {
                setPopup({title: 'Ошибка получения списка регионов', message: error.message});
                setCanSave(false);
            });
        }).catch((error: Error) => {
            setPopup({title: 'Ошибка получения списка стран', message: error.message});
            setCanSave(false);
        })
    }, []);

    const handleClose = () => {
        //setCurrentRequest(null);
        setOpen(false);
    }

    if (!open) {
        return (<></>);
    }

    const handleInnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "inncargo") {
            setInncargo(event.target.value)
            setCargoError(null);
        } else if (event.target.name === "innoperator") {
            setInnoperator(event.target.value)
            setOperatorError(null);
        } else if (event.target.name === "innmaster") {
            setInnmaster(event.target.value)
            setMasterError(null);
        } else if (event.target.name === "innconsignee") {
            setInnconsignee(event.target.value)
            setConsigneeError(null);
        }
        if (event.target.value.length === 10 || event.target.value.length === 12) {
            REST.getCompanyByInn(BigInt(event.target.value), true)
                .then(c => {
                    if (event.target.name === "inncargo") {
                        setNameCargo(c.name ?? '');
                    } else if (event.target.name === "innoperator") {
                        setNameOperator(c.name ?? '');
                    } else if (event.target.name === "innmaster") {
                        setNameMaster(c.name ?? '');
                    } else if (event.target.name === "innconsignee") {
                        setNameConsignee(c.name ?? '');
                    }
                    handleOnChange();
                })
                .catch((e: Error) => {
                    if (event.target.name === "inncargo") {
                        setCargoError(e.message);
                    } else if (event.target.name === "innoperator") {
                        setOperatorError(e.message);
                    } else if (event.target.name === "innmaster") {
                        setMasterError(e.message);
                    } else if (event.target.name === "innconsignee") {
                        setConsigneeError(e.message);
                    }
                });
        }
    }

    const handleIMOChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setImonumber(event.target.value);
        if (event.target.value.length > 4) {
            REST.getVesselByIMO(parseInt(event.target.value)).then(v => {
                if (v.length > 0) {
                    setOtbname(v[0].name ?? '');
                    /*for (let i = 0; i < flag.current!.children.length; i++) {
                        if (flag.current!.item(i)!.value === v[0].flag.toString())
                            flag.current!.item(i)!.selected = true;
                    }*/
                    setRegistrynumber(v[0].register_number ?? '');
                    setReestrnumber(v[0].registry_number === undefined ? '' : (v[0].registry_serial + " " + v[0].registry_number));

                }
            })
        }
        handleOnChange();
    }

    const handleOnChange = () => {
        /*if (e !== null) {
            var inp = inputs;
            inp[e!.target.name] = e!.target.value;
            setInputs(inp);
        }

        console.log(inputs);*/

        //setCanSave(true
            //otbname.length > 0 &&
            //imonumber.length > 0// &&
            //registrynumber.length > 0 &&
            //reestrnumber.length > 0 &&
            //contact.length > 0 &&
            //inport.length > 0 &&
            //fromport.length > 0 &&
            //outport.length > 0 &&
            //target.length > 0 &&
            //place.length > 0 &&
            //cargo.length > 0 &&
            //count.length > 0 &&
            //indatetime.length > 0 &&
            //startloadtime.length > 0 &&
            //endloadtime.length > 0 &&
            //addressobject.length > 0 &&
            //addressobjectregion.length > 0 &&
            //contactoperator.length > 0 &&
            //contactmaster.length > 0 &&
            //nameCargo.length > 0 &&
            //nameOperator.length > 0 &&
            //nameMaster.length > 0 &&
            //nameConsignee.length > 0 &&
            //contactcargo.length > 0 &&
            //needOperations > 0
        //);
    }

    const createRequest = () => {
        let newRequest = {
            otb: otbname,
            flag: {id: parseInt(flag)},
            imonumber: imonumber,//TODO: long
            registrynumber: registrynumber,
            reestrnumber: reestrnumber,
            contact: contact,
            inport: inport,
            incountry: {id: parseInt(incountry)},
            fromport: fromport,
            fromcountry: {id: parseInt(fromcountry)},
            outport: outport,
            outcountry: {id: parseInt(outcountry)},
            target: target,
            place: place,
            cargo: cargo,
            count: count,
            indatetime: indatetime,
            startloadtime: startloadtime,
            endloadtime: endloadtime,
            addressobject: addressobject,
            addressobjectregion: {id: parseInt(addressobjectregion === '' ? regions.at(0)!.id.toString() : addressobjectregion)},
            nameoperator: nameOperator,
            contactoperator: contactoperator,
            mastername: nameMaster,
            contactmaster: contactmaster,
            namecargo: nameCargo,
            contactcargo: contactcargo,
            nameconsignee: nameConsignee,
            need_operations: needOperations,
            executor: {inn: parseInt(executor === '' ? executors.at(0)!.inn.toString() : executor)}
        }
        REST.createRequest(newRequest).then(r => {
            props.setRequests((prev: RequestType[]) => [r, ...prev]);
            handleClose();
        }).catch((error: Error) => {
            setPopup({title: 'Ошибка создания заявки', message: error.message});
        });
    }

    return (<Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}
                    slotProps={{
                        paper: {
                            component: 'form',
                            onSubmit: (event: FormEvent<HTMLFormElement>) => {
                                event.preventDefault();
                                createRequest();
                            }
                        }
                    }}>
            <DialogTitle>Новая заявка</DialogTitle>
            <DialogContent dividers>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'otbname'}>Наименование судна</InputLabel>
                        <OutlinedInput id={'otbname'} name={"otbname"} label={"Наименование судна"}
                                       onChange={(e) => setOtbname(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'flag'}>Флаг</InputLabel>
                        <Select defaultValue={flag} id="flag" name="flag" label={'Флаг'}
                                onChange={(e) => setFlag(e.target.value)}>
                            {countries.map((c: CountryType) => <MenuItem value={c.id.toString()}
                                                                         key={c.id}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'imonumber'}>ИМО номер</InputLabel>
                        <OutlinedInput id={'imonumber'} name={"imonumber"} label={"ИМО номер"} placeholder={"1234567"}
                                       type={'number'} inputProps={{min: 1}} onChange={handleIMOChange}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'registrynumber'}>Регистровый номер</InputLabel>
                        <OutlinedInput id={'registrynumber'} name={"registrynumber"} label={"Регистровый номер"}
                                       onChange={(e) => setRegistrynumber(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'reestrnumber'}>Реестровый номер</InputLabel>
                        <OutlinedInput id={'reestrnumber'} name={"reestrnumber"} label={"Реестровый номер"}
                                       onChange={(e) => setReestrnumber(e.target.value)}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth required sx={{mb: 2}}>
                    <InputLabel htmlFor={'contact'}>Контакты судна</InputLabel>
                    <OutlinedInput id={'contact'} name={"contact"} label={"Контакты судна"}
                                   onChange={(e) => setContact(e.target.value)}/>
                </FormControl>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'inport'}>Приход в порт</InputLabel>
                        <OutlinedInput id={'inport'} name={"inport"} label={"Приход в порт"}
                                       onChange={(e) => setInport(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'incountry'}>Страна</InputLabel>
                        <Select defaultValue={incountry} id="incountry" name="incountry" label={'Страна'}
                                onChange={(e) => setIncountry(e.target.value)}>
                            {countries.map((c: CountryType) => <MenuItem value={c.id.toString()}
                                                                         key={c.id}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'fromport'}>Из порта</InputLabel>
                        <OutlinedInput id={'fromport'} name={"fromport"} label={"Из порта"}
                                       onChange={(e) => setFromport(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'fromcountry'}>Страна</InputLabel>
                        <Select defaultValue={fromcountry} id="fromcountry" name="fromcountry" label={'Страна'}
                                onChange={(e) => setFromcountry(e.target.value)}>
                            {countries.map((c: CountryType) => <MenuItem value={c.id.toString()}
                                                                         key={c.id}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'outport'}>Отход в порт</InputLabel>
                        <OutlinedInput id={'outport'} name={"outport"} label={"Отход в порт"}
                                       onChange={(e) => setOutport(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'outcountry'}>Страна</InputLabel>
                        <Select defaultValue={outcountry} id="outcountry" name="outcountry" label={'Страна'}
                                onChange={(e) => setOutcountry(e.target.value)}>
                            {countries.map((c: CountryType) => <MenuItem value={c.id.toString()}
                                                                         key={c.id}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'target'}>Цель захода</InputLabel>
                        <OutlinedInput id={'target'} name={"target"} label={"Цель захода"}
                                       onChange={(e) => setTarget(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'place'}>Место</InputLabel>
                        <OutlinedInput id={'place'} name={"place"} label={"Место"}
                                       onChange={(e) => setPlace(e.target.value)}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'cargo'}>Груз</InputLabel>
                        <OutlinedInput id={'cargo'} name={"cargo"} label={"Груз"}
                                       onChange={(e) => setCargo(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'count'}>Кол-во (т/м<sup>3</sup>)</InputLabel>
                        <OutlinedInput id={'count'} name={"count"} label={"Кол-во (т/м3"}
                                       onChange={(e) => setCount(e.target.value)}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth required sx={{mb: 2}}>
                    <TextField id={'indatetime'} name={"indatetime"} type={'datetime-local'} required
                               slotProps={{inputLabel: {shrink: true,},}}
                               label={"Ориентировочная дата и время прибытия"}
                               onChange={(e) => setIndatetime(e.target.value)}/>
                </FormControl>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required sx={{mb: 2}}>
                        <TextField id={'startloadtime'} name={"startloadtime"} type={'date'} required
                                   slotProps={{inputLabel: {shrink: true,},}}
                                   label={"Ориентировочная дата начала погрузки"}
                                   onChange={(e) => setStartloadtime(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required sx={{mb: 2}}>
                        <TextField id={'endloadtime'} name={"endloadtime"} type={'date'} required
                                   slotProps={{inputLabel: {shrink: true,},}}
                                   label={"Ориентировочная дата окончания погрузки"}
                                   onChange={(e) => setEndloadtime(e.target.value)}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'addressobject'}>Адрес места погрузки</InputLabel>
                        <OutlinedInput id={'addressobject'} name={"addressobject"} label={"Адрес места погрузки"}
                                       onChange={(e) => setAddressobject(e.target.value)}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor={'addressobjectregion'}>Регион</InputLabel>
                        <Select defaultValue={regions.at(0)!.id.toString()} id="addressobjectregion"
                                name="addressobjectregion" label={'Регион'}
                                onChange={(e) => setAddressobjectregion(e.target.value)}>
                            {regions.map((r: RegionType) => <MenuItem value={r.id.toString()}
                                                                      key={r.id}>{r.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <TextField id={'innoperator'} name={"innoperator"} required
                                   label={operatorError !== null ? operatorError : "Оператор"}
                                   type='number' onChange={handleInnChange} placeholder={"1234567890"}
                                   slotProps={{
                                       input: {
                                           startAdornment: <InputAdornment position="start">ИНН</InputAdornment>,
                                           inputProps: {min: 1, max: 999999999999, step: 1}
                                       }
                                   }} error={operatorError !== null}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <TextField id={'nameOperator'} disabled name={"nameOperator"} label={"Наименование"}
                                   defaultValue={nameOperator}
                                   onChange={(e) => setNameOperator(e.target.value)}
                                   slotProps={{inputLabel: {shrink: true,},}}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth required sx={{mb: 2}}>
                    <InputLabel htmlFor={'contactoperator'}>Контакты оператора</InputLabel>
                    <OutlinedInput id={'contactoperator'} name={"contactoperator"} label={"Контакты оператора"}
                                   onChange={(e) => setContactoperator(e.target.value)}/>
                </FormControl>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <TextField id={'innmaster'} name={"innmaster"} required
                                   label={masterError !== null ? masterError : "Судовладелец"}
                                   type='number' onChange={handleInnChange} placeholder={"1234567890"}
                                   slotProps={{
                                       input: {
                                           startAdornment: <InputAdornment position="start">ИНН</InputAdornment>,
                                           inputProps: {min: 1, max: 999999999999, step: 1}
                                       }
                                   }} error={masterError !== null}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <TextField id={'nameMaster'} disabled name={"nameMaster"} label={"Наименование"}
                                   defaultValue={nameMaster}
                                   onChange={(e) => setNameMaster(e.target.value)}
                                   slotProps={{inputLabel: {shrink: true,},}}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth required sx={{mb: 2}}>
                    <InputLabel htmlFor={'contactmaster'}>Контакты судовладельца</InputLabel>
                    <OutlinedInput id={'contactmaster'} name={"contactmaster"} label={"Контакты судовладельца"}
                                   onChange={(e) => setContactmaster(e.target.value)}/>
                </FormControl>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth required>
                        <TextField id={'inncargo'} name={"inncargo"} required
                                   label={cargoError !== null ? cargoError : "Грузоотправитель"}
                                   type='number' onChange={handleInnChange} placeholder={"1234567890"}
                                   slotProps={{
                                       input: {
                                           startAdornment: <InputAdornment position="start">ИНН</InputAdornment>,
                                           inputProps: {min: 1, max: 999999999999, step: 1}
                                       }
                                   }} error={cargoError !== null}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <TextField id={'nameCargo'} disabled name={"nameCargo"} label={"Наименование"}
                                   defaultValue={nameCargo}
                                   onChange={(e) => setNameCargo(e.target.value)}
                                   slotProps={{inputLabel: {shrink: true,},}}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth required sx={{mb: 2}}>
                    <InputLabel htmlFor={'contactcargo'}>Контакты грузоотправителя</InputLabel>
                    <OutlinedInput id={'contactcargo'} name={"contactcargo"} label={"Контакты грузоотправителя"}
                                   onChange={(e) => setContactcargo(e.target.value)}/>
                </FormControl>
                <Stack direction={"row"} spacing={1} sx={{mb: 2}}>
                    <FormControl fullWidth required>
                        <TextField id={'innconsignee'} name={"innconsignee"} required
                                   label={consigneeError !== null ? consigneeError : "Грузополучатель"}
                                   type='number' onChange={handleInnChange} placeholder={"1234567890"}
                                   slotProps={{
                                       input: {
                                           startAdornment: <InputAdornment position="start">ИНН</InputAdornment>,
                                           inputProps: {min: 1, max: 999999999999, step: 1}
                                       }
                                   }} error={consigneeError !== null}/>
                    </FormControl>
                    <FormControl fullWidth required>
                        <TextField id={'nameConsignee'} disabled name={"nameConsignee"} label={"Наименование"}
                                   defaultValue={nameConsignee}
                                   onChange={(e) => setNameConsignee(e.target.value)}
                                   slotProps={{inputLabel: {shrink: true,},}}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth required sx={{mb: 2}} disabled={executors.length === 0}>
                    <InputLabel htmlFor={'executor'}>Исполнитель</InputLabel>
                    {executors.length > 0
                        ? <Select defaultValue={executors.at(0)!.inn.toString()} id="executor"
                                  name="executor" label={'Исполнитель'}
                                  onChange={(e) => setExecutor(e.target.value)}>
                            {executors.map((c: CompanyType) => <MenuItem value={c.inn.toString()}
                                                                         key={c.inn}>{c.name + ' (ИНН ' + c.inn + ')'}</MenuItem>)}
                        </Select>
                        : <Select defaultValue={'0'} id="executor" name="executor" label={'Исполнитель'}>
                            <MenuItem value={'0'} key={0}>Нет доступных исполнителей</MenuItem>)
                        </Select>
                    }
                </FormControl>

                <FormControl fullWidth component="fieldset" variant="standard" required>
                    <FormLabel component="legend">Необходимые работы:</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox name="under"
                                               onChange={(e) =>
                                                   setNeedOperations(needOperations | (e.target.checked ? 1 : 0))}/>}
                            label="Осмотр подводной части судна"
                        />
                        <FormControlLabel
                            control={<Checkbox name="room"
                                               onChange={(e) =>
                                                   setNeedOperations(needOperations | (e.target.checked ? 2 : 0))}/>}
                            label="Осмотр помещений судна"
                        />
                        <FormControlLabel
                            control={<Checkbox name="cargo"
                                               onChange={(e) =>
                                                   setNeedOperations(needOperations | (e.target.checked ? 4 : 0))}/>}
                            label="Досмотр грузовых операций на судне"
                        />
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={'error'}>Отмена</Button>
                <Button variant={"contained"} color={'primary'} disabled={!canSave} type={"submit"}
                        onClick={(e) => {
                            //e.preventDefault();
                            //createRequest();
                        }}>Создать</Button>
            </DialogActions>
        </Dialog>
    );
}
