import React, {ChangeEvent, ReactElement, useRef, useState} from "react";
import {REST} from "./REST";
import {OtbJwt} from "./types/OtbJwt";
import {
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button, Chip
} from "@mui/material";
import {Popup, PopupType} from "./Popup";

export function LoginPage(props: any): ReactElement {
    const version: string = process.env.REACT_APP_VERSION ?? '0.0.0';
    const newPassword = useRef<HTMLInputElement | null>(null);
    const [needChangePassword, setNeedChangePassword] = useState<boolean>(false);
    const [passwordEqual, setPasswordEqual] = useState<boolean>(false);

    const [popup, setPopup] = useState<PopupType | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [currentUserName, setCurrentUserName] = useState<string | null>(null);
    const [currentPassword, setCurrentPassword] = useState<string | null>(null);

    const auth = () => {
        setLoading(true);
        REST.login(currentUserName!, currentPassword!).then((jwt: OtbJwt) => {
            setLoading(false);
            if (jwt.user.need_change_password) {
                setCurrentUserName('');
                setCurrentPassword('');
                setNeedChangePassword(true);
            } else {
                document.location = "/";
            }
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка авторизации', message: error.message});
        });
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        /*if (password.current!.value === newPassword.current!.value && password.current!.value.length > 0) {
            setPasswordEqual(true);
        } else {
            setPasswordEqual(false);
        }*/
    }

    /*if (needChangePassword) {
        return (<main style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                flexDirection: "column"
            }}>
                <section className={"LoginPage"}>
                    <h4>{process.env.REACT_APP_TITLE}</h4>

                    <input className={"LoginInput"} placeholder="Новый пароль" type="password" ref={password}
                           name={"newPassword"} onChange={handleOnChange} defaultValue={""}/>
                    <input className={"LoginInput"} placeholder="Повторите пароль" type="password" ref={newPassword}
                           name={"repeatPassword"} onChange={handleOnChange} defaultValue={""}/>

                    <div className={"Button Primary LoginButton" + (passwordEqual ? "" : " Disabled")}
                         style={{height: "36px"}} onClick={(e) => {
                        e.preventDefault();
                        if (passwordEqual) {
                            REST.changePassword(newPassword.current!.value).then(() => document.location = "/");
                        }
                    }}>{loading
                        ? <Loader color={"var(--otb-foreground)"}/>
                        : "Сменить пароль"
                    }
                    </div>
                </section>
                <div style={{
                    color: "rgba(129, 140, 153, 0.5)",
                    width: "288px",
                    textAlign: "right",
                    paddingRight: "12px",
                    paddingLeft: "12px"
                }}>v{version}</div>
            </main>
        )
    }*/

    const handleOnChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(event.target.value);
        setPopup(null);
    }

    const handleOnChangeUserName = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentUserName(event.target.value);
        setPopup(null);
    }

    return (<>
            <Dialog open={true} sx={{backgroundColor: 'rgb(2, 136, 209)'}} slots={{backdrop: 'span'}}
                    component={'form'}>
                <DialogTitle>
                    <Stack direction={"row"} spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
                            <Typography component={"h5"} variant={"h5"}
                                        sx={{textAlign: "center"}}>{process.env.REACT_APP_TITLE}</Typography>
                        <Chip color="primary" size="small" label={"v" + version} sx={{display: {xs: 'none', sm: 'flex'},}}/>
                    </Stack>
                </DialogTitle>
                <DialogContent>

                    <TextField sx={{mt: 2}}
                               id={"username"}
                               label={"Логин"}
                               name={"username"}
                               type={"text"}
                               onChange={handleOnChangeUserName}
                               onInput={handleOnChangeUserName}
                               fullWidth
                               required
                    />

                    <TextField sx={{mt: 2}}
                               id={"password"}
                               label={"Пароль"}
                               name={"password"}
                               type={"password"}
                               autoComplete={"current-password"}
                               onChange={handleOnChangePassword}
                               onInput={handleOnChangePassword}
                               fullWidth
                               required
                    />

                </DialogContent>
                <DialogActions sx={{mr: 2, ml: 2, mb: 2}}>
                    <Button loading={loading} size="large" type="submit" variant="contained" fullWidth
                            onClick={(e) => auth()}>Войти</Button>
                </DialogActions>
            </Dialog>
            <Popup popup={[popup, setPopup]}/>
        </>
    )
}
