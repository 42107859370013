import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {RequestType, Status} from "../../types/RequestType";
import {RequestModal} from "./modal/RequestModal";
import {Utils} from "../../utils/Utils";
import {Popup, PopupType} from "../../Popup";
import {TabPanel} from "@mui/lab";
import {
    Button, Chip, CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { NewRequestModal } from "./modal/NewRequestModal";

export function TabOutRequests(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;

    const [requests, setRequests] = useState<RequestType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showNewModal, setShowNewModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentRequest, setCurrentRequest] = useState<RequestType | null>(null);

    useEffect(() => {
        REST.getRequest("out").then((r: RequestType[]) => {
            setRequests(r);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>
            <Button variant="contained" disabled={loading} onClick={() => setShowNewModal(true)}>Создать
                заявку</Button>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'center'}>Статус</TableCell>
                            <TableCell>№, дата</TableCell>
                            <TableCell>Судно</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                {loading
                    ? <TableRow>
                        <TableCell colSpan={3}><CircularProgress/></TableCell>
                    </TableRow>
                    : requests.length === 0
                        ? <TableRow>
                            <TableCell colSpan={3}>Нет исходящих заявок</TableCell>
                        </TableRow>
                        : requests.filter(r => r !== undefined).map((r: RequestType) =>
                            <TableRow key={r.id} hover={true} sx={{cursor: 'pointer'}}
                                      onClick={() => {
                                          setCurrentRequest(r);
                                          setShowModal(true);
                                      }}>
                                <TableCell align={'center'}><Chip label={Status[r.status].name} color={Status[r.status].color}/></TableCell>
                                <TableCell>{'Заявка №' + r.id + ' от ' + Utils.formatDate(r.date)}</TableCell>
                                <TableCell>{r.otb}</TableCell>
                            </TableRow>
                        )}


                    </TableBody>
                </Table>
            </TableContainer>

        </TabPanel>

        <NewRequestModal show={[showNewModal, setShowNewModal]} currentRequestState={[currentRequest, setCurrentRequest]} setRequests={setRequests} popup={[popup, setPopup]}/>
        <RequestModal show={[showModal, setShowModal]} currentRequestState={[currentRequest, setCurrentRequest]}/>

        <Popup popup={[popup, setPopup]}/>
    </>);

}
