import {ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import {RequestType, Status, StatusEnum} from "../../../types/RequestType";
import {REST} from "../../../REST";
import {EmployeeType} from "../../../types/EmployeeType";
import {RequestBlockType} from "../../../types/RequestBlockType";
import {Utils} from "../../../utils/Utils";
import {Popup, PopupType} from "../../../Popup";
import {
    Button, Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel,
    InputLabel, MenuItem, OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack
} from "@mui/material";
import {RequestBlock} from "../request_block/RequestBlock";

export function RequestModal(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
    const readOnly: boolean = props.readOnly as boolean;
    const [open, setOpen] = props.show as [boolean, Dispatch<SetStateAction<boolean>>];
    const [currentRequest, setCurrentRequest] = props.currentRequestState as [RequestType | null, Dispatch<SetStateAction<RequestType | null>>];
    const [subordinates, setSubordinates] = useState<EmployeeType[]>([]);
    const [blocks, setBlocks] = useState<RequestBlockType[]>([]);
    const [newBlock, setNewBlock] = useState<RequestBlockType | null>(null);
    const [isBlockSaved, setBlockSaved] = useState<boolean>(false);

    useEffect(() => {
        REST.getSubordinates().then(s => {
            setSubordinates(s);
        }).catch((error: Error) => {
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    useEffect(() => {
        setBlocks(currentRequest?.blocks ?? []);
    }, [currentRequest]);

    const handleClose = () => {
        setCurrentRequest(null);
        setOpen(false);
    }

    const statusRequest = (id: bigint, status: number) => {
        REST.setRequestStatus(id, status).then((r: RequestType) => {
            handleClose();
            //TODO: update only one record in table
        }).catch((error: Error) => {
            setPopup({title: 'Ошибка изменения статуса', message: error.message});
        })
    }

    const changeFinalExecutor = (e: SelectChangeEvent) => {
        const finalExecutorID = parseInt(e.target.value);
        const payload = {
            id: currentRequest?.id,
            feid: finalExecutorID
        }
        REST.changeFinalExecutor(payload).catch((error: Error) => {
            setPopup({title: 'Ошибка назначения исполнителя', message: error.message});
        });
    }

    /*const uploadDocument = (r: RequestType | undefined) => {
        let file: HTMLInputElement = document.getElementById("document") as HTMLInputElement;
        let fileType: HTMLInputElement = document.getElementById("doctype") as HTMLInputElement;
        if (file.files!.length === 0)
            return
        let formData = new FormData();

        formData.append("data", file.files!.item(0)!);
        formData.append("name", file.files!.item(0)!.name);
        formData.append("type", fileType.value);
        formData.append("rid", r!.id.toString());

        REST.uploadFile(formData).then((f: FileType) => {
            file.value = '';
            if (currentRequest === undefined)
                currentRequest.files = [f];
            else
                currentRequest.files?.push(f);
        });
        //TODO: add spinner while upload
        //TODO: add error button on fail
    };*/

    if (!open) {
        return (<></>);
    }

    const existsBlockType = (type: number) => {
        return ((blocks.filter((b) => b.type === type).length ?? 0) > 0);
        /*if((blocks.length??0) > 0) {
            return (blocks.at((blocks.length) - 1)!.type) === type;
        }
        return false;*/
        //console.log(type + ":" + ret);
    }

    const addNewBlock = (type: number) => {
        setNewBlock({
            id: undefined,
            rid: currentRequest?.id!,
            type: type,
            result: null,
            violations: null,
            prohibited_objects: null,
            cargo: null,
            photo_ts: null,
            photo_holds: null,
            photos: null,
            ship_role: null,
            lading_bill: null,
            manifesto: null,
            inspection_report: null,
            sealing_act: null,
            created_at: null,
            start_at: null,
            finish_at: null
        });
        setBlockSaved(false);
    }

    return (<>
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle sx={{alignSelf: 'center'}}>
                <Chip label={Status[currentRequest?.status ?? 0].name}
                      color={Status[currentRequest?.status ?? 0].color} sx={{mr: 1}}/>
                {"Заявка №" + currentRequest?.id + " от " + Utils.formatDate(currentRequest?.date)}
            </DialogTitle>
            <DialogContent dividers>

                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'customername'}>Заказчик</InputLabel>
                        <OutlinedInput id={'customername'} name={"customername"} label={"Заказчик"}
                                       defaultValue={currentRequest?.customer.name}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'otbname'}>Наименование судна</InputLabel>
                        <OutlinedInput id={'otbname'} name={"otbname"} label={"Наименование судна"}
                                       defaultValue={currentRequest?.otb}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'flag'}>Флаг</InputLabel>
                        <OutlinedInput id={'flag'} name={"flag"} label={"Флаг"}
                                       defaultValue={currentRequest?.flag.name}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'imonumber'}>ИМО номер</InputLabel>
                        <OutlinedInput id={'imonumber'} name={"imonumber"} label={"ИМО номер"}
                                       defaultValue={currentRequest?.imonumber.toString()}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'registrynumber'}>Регистровый номер</InputLabel>
                        <OutlinedInput id={'registrynumber'} name={"registrynumber"} label={"Регистровый номер"}
                                       defaultValue={currentRequest?.registrynumber}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'reestrnumber'}>Реестровый номер</InputLabel>
                        <OutlinedInput id={'reestrnumber'} name={"reestrnumber"} label={"Реестровый номер"}
                                       defaultValue={currentRequest?.reestrnumber}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth disabled={true} sx={{mb: 2}}>
                    <InputLabel htmlFor={'contact'}>Контакты судна</InputLabel>
                    <OutlinedInput id={'contact'} name={"contact"} label={"Контакты судна"}
                                   defaultValue={currentRequest?.contact}/>
                </FormControl>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'inport'}>Приход в порт (причал)</InputLabel>
                        <OutlinedInput id={'inport'} name={"inport"} label={"Приход в порт (причал)"}
                                       defaultValue={currentRequest?.inport}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'incountry'}>Cтрана</InputLabel>
                        <OutlinedInput id={'incountry'} name={"incountry"} label={"Cтрана"}
                                       defaultValue={currentRequest?.incountry.name}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'fromport'}>Из порта</InputLabel>
                        <OutlinedInput id={'fromport'} name={"fromport"} label={"Из порта"}
                                       defaultValue={currentRequest?.fromport}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'fromcountry'}>Cтрана</InputLabel>
                        <OutlinedInput id={'fromcountry'} name={"fromcountry"} label={"Cтрана"}
                                       defaultValue={currentRequest?.fromcountry.name}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'outport'}>Отход в порт</InputLabel>
                        <OutlinedInput id={'outport'} name={"outport"} label={"Отход в порт"}
                                       defaultValue={currentRequest?.outport}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'outcountry'}>Cтрана</InputLabel>
                        <OutlinedInput id={'outcountry'} name={"outcountry"} label={"Cтрана"}
                                       defaultValue={currentRequest?.outcountry.name}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'target'}>Цель захода</InputLabel>
                        <OutlinedInput id={'target'} name={"target"} label={"Цель захода"}
                                       defaultValue={currentRequest?.target}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'place'}>Место</InputLabel>
                        <OutlinedInput id={'place'} name={"place"} label={"Место"}
                                       defaultValue={currentRequest?.place}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'cargo'}>Груз</InputLabel>
                        <OutlinedInput id={'cargo'} name={"cargo"} label={"Груз"} defaultValue={currentRequest?.cargo}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'count'}>Кол-во (т./куб.м)</InputLabel>
                        <OutlinedInput id={'count'} name={"count"} label={"Кол-во (т./куб.м)"}
                                       defaultValue={currentRequest?.count}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth disabled={true} sx={{mb: 2}}>
                    <InputLabel htmlFor={'indatetime'}>Ориентировочная дата и время прибытия</InputLabel>
                    <OutlinedInput id={'indatetime'} name={"indatetime"} label={"Ориентировочная дата и время прибытия"}
                                   defaultValue={Utils.formatDateTime(currentRequest?.indatetime)}/>
                </FormControl>
                <FormControl fullWidth disabled={true} sx={{mb: 2}}>
                    <InputLabel htmlFor={'loadtime'}>Ориентировочная дата начала и окончания погрузки</InputLabel>
                    <OutlinedInput id={'loadtime'} name={"loadtime"}
                                   label={"Ориентировочная дата начала и окончания погрузки"}
                                   defaultValue={Utils.formatDate(currentRequest?.startloadtime) + ' - ' + Utils.formatDate(currentRequest?.endloadtime)}/>
                </FormControl>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'addressobject'}>Адрес места погрузк</InputLabel>
                        <OutlinedInput id={'addressobject'} name={"addressobject"} label={"Адрес места погрузк"}
                                       defaultValue={currentRequest?.addressobject}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'addressobjectregion'}>Регион</InputLabel>
                        <OutlinedInput id={'addressobjectregion'} name={"addressobjectregion"} label={"Регион"}
                                       defaultValue={currentRequest?.addressobjectregion.name}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'nameoperator'}>Наименование оператора</InputLabel>
                        <OutlinedInput id={'nameoperator'} name={"nameoperator"} label={"Наименование оператора"}
                                       defaultValue={currentRequest?.nameoperator}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'contactoperator'}>Контакты оператора</InputLabel>
                        <OutlinedInput id={'contactoperator'} name={"contactoperator"} label={"Контакты оператора"}
                                       defaultValue={currentRequest?.contactoperator}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'mastername'}>Cудовладелец</InputLabel>
                        <OutlinedInput id={'mastername'} name={"mastername"} label={"Cудовладелец"}
                                       defaultValue={currentRequest?.mastername}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'contactmaster'}>Контакты судовладельца</InputLabel>
                        <OutlinedInput id={'contactmaster'} name={"contactmaster"} label={"Контакты судовладельца"}
                                       defaultValue={currentRequest?.contactmaster}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'namecargo'}>Грузоотправитель</InputLabel>
                        <OutlinedInput id={'namecargo'} name={"namecargo"} label={"Грузоотправитель"}
                                       defaultValue={currentRequest?.namecargo}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'contactcargo'}>Контакты грузоотправителя</InputLabel>
                        <OutlinedInput id={'contactcargo'} name={"contactcargo"} label={"Контакты грузоотправителя"}
                                       defaultValue={currentRequest?.contactcargo}/>
                    </FormControl>
                </Stack>
                <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'nameconsignee'}>Грузополучатель</InputLabel>
                        <OutlinedInput id={'nameconsignee'} name={"nameconsignee"} label={"Грузополучатель"}
                                       defaultValue={currentRequest?.nameconsignee}/>
                    </FormControl>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel htmlFor={'executor'}>Исполнитель</InputLabel>
                        <OutlinedInput id={'executor'} name={"executor"} label={"Исполнитель"}
                                       defaultValue={currentRequest?.executor.name}/>
                    </FormControl>
                </Stack>
                <FormControl fullWidth component="fieldset" variant="standard" disabled={true} sx={{mb: 2}}>
                    <FormLabel component="legend">Необходимые работы:</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox name="under"
                                               checked={(currentRequest?.need_operations ?? 0 & 1) === 1}/>}
                            label="Осмотр подводной части судна"
                        />
                        <FormControlLabel
                            control={<Checkbox name="room" checked={(currentRequest?.need_operations ?? 0 & 2) === 2}/>}
                            label="Осмотр помещений судна"
                        />
                        <FormControlLabel
                            control={<Checkbox name="cargo"
                                               checked={(currentRequest?.need_operations ?? 0 & 4) === 4}/>}
                            label="Досмотр грузовых операций на судне"
                        />
                    </FormGroup>
                </FormControl>


                {Utils.hasRole("water_ptb_request")
                    ? <FormControl fullWidth
                                   disabled={readOnly || subordinates?.length === 1 || Status[currentRequest?.status ?? 0].value === "REJECTED" || Status[currentRequest?.status ?? 0].value === "EXECUTED"}
                                   sx={{mb: 2}}>
                        <InputLabel htmlFor={'finalexecutor'}>Назначение</InputLabel>
                        {readOnly || subordinates?.length === 1 || Status[currentRequest?.status ?? 0].value === "REJECTED" || Status[currentRequest?.status ?? 0].value === "EXECUTED"
                            ? <OutlinedInput id={'finalexecutor'} name={"finalexecutor"} label={"Назначение"}
                                             defaultValue={currentRequest?.final_executor.fio}/>
                            : <Select id="finalexecutor" name="finalexecutor"
                                      onChange={changeFinalExecutor} label={"Назначение"}
                                      defaultValue={subordinates?.find((s) => s.id === currentRequest?.final_executor.id)?.id.toString()}>
                                {subordinates?.map((s: EmployeeType) =>
                                    <MenuItem value={s.id.toString()} key={s.id}
                                              disabled={s.disabled}>{s.fio + (s.cert ? (' (' + s.cert + ')') : '')}</MenuItem>)
                                }
                            </Select>
                        }
                    </FormControl>
                    : ''
                }
                <Divider sx={{mb: 2}}/>
                {blocks.map((b, i) => <RequestBlock block={b} key={i} blocks={[blocks, setBlocks]}
                                                    saved={[isBlockSaved, setBlockSaved]}
                                                    requestNumber={currentRequest?.id}
                                                    readOnly={readOnly}
                                                    popup={[popup, setPopup]}/>)}
                {me.cert !== null && Status[currentRequest?.status ?? 0].value === 'PROCESS'
                    ? <Stack direction={"row"} spacing={1} sx={{mt: 2, justifyContent: 'center'}}>
                        {
                            existsBlockType(1)
                                ? (existsBlockType(3)
                                        ? (existsBlockType(5)
                                                ? ""
                                                : (!isBlockSaved && newBlock !== null
                                                    ? <RequestBlock block={newBlock}
                                                                    blocks={[blocks, setBlocks]}
                                                                    saved={[isBlockSaved, setBlockSaved]}
                                                                    requestNumber={currentRequest?.id}
                                                                    popup={[popup, setPopup]}/>
                                                    : (<>
                                                        <Button variant={"contained"} color={'primary'}
                                                                onClick={() => addNewBlock(3)}>Добавить отчет о
                                                            досмотре</Button>
                                                        <Button variant={"contained"} color={'primary'}
                                                                onClick={() => addNewBlock(5)}>Добавить отчет о результатах
                                                            досмотра</Button>
                                                    </>))
                                        )
                                        : (!isBlockSaved && newBlock !== null
                                            ? <RequestBlock block={newBlock}
                                                            blocks={[blocks, setBlocks]}
                                                            saved={[isBlockSaved, setBlockSaved]}
                                                            requestNumber={currentRequest?.id}
                                                            popup={[popup, setPopup]}/>
                                            : (<>
                                                <Button variant={"contained"} color={'primary'}
                                                        onClick={() => addNewBlock(3)}>Добавить отчет о досмотре</Button>
                                                <Button variant={"contained"} color={'primary'}
                                                        onClick={() => addNewBlock(5)}>Добавить отчет о результатах
                                                    досмотра</Button>
                                            </>))

                                )
                                : (!isBlockSaved && newBlock !== null
                                        ? <RequestBlock block={newBlock}
                                                        blocks={[blocks, setBlocks]}
                                                        saved={[isBlockSaved, setBlockSaved]}
                                                        requestNumber={currentRequest?.id}
                                                        popup={[popup, setPopup]}/>
                                        : <Button variant={"contained"} color={'primary'} onClick={() => addNewBlock(1)}>Добавить
                                            отчет о начале досмотра</Button>
                                )
                        }
                    </Stack>
                    : ''
                }
            </DialogContent>
            <DialogActions>
                {currentRequest?.status === StatusEnum.REJECTED
                    ? ''
                    : <Button variant={"contained"} disabled
                              onClick={() => alert('Будет произведена отправка в ЕГИС ОТБ')}>Отправить
                        в ЕГИС ОТБ</Button>
                }
                <Button variant={"contained"} color={'primary'}
                        onClick={() => REST.downloadReport(currentRequest, 'docx')}>Скачать</Button>
                {Utils.hasRole('water_ptb_request') && currentRequest?.status === StatusEnum.NEW ?
                    <Button variant={"contained"} color={'primary'}
                            onClick={() => statusRequest(currentRequest?.id!, StatusEnum.REVIEW)}>В
                        рассмотрение</Button>
                    : ''}
                {Utils.hasRole('water_ptb_request') && currentRequest?.status === StatusEnum.REVIEW ?
                    <Button variant={"contained"} color={'warning'}
                            onClick={() => statusRequest(currentRequest?.id!, StatusEnum.PROCESS)}>Начать досмотр
                    </Button>
                    : ''}
                {Utils.hasRole('water_ptb_request') && currentRequest?.status === StatusEnum.PROCESS && me.login === me.company.inn.toString() ?
                    <Button variant={"contained"} color={'success'}
                            onClick={() => statusRequest(currentRequest?.id!, StatusEnum.EXECUTED)}>Исполнить
                    </Button>
                    : ''}
                {Utils.hasRole('water_ptb_request') && (currentRequest?.status === StatusEnum.NEW || currentRequest?.status === StatusEnum.REVIEW || currentRequest?.status === StatusEnum.PROCESS) && me.login === me.company.inn.toString() ?
                    <Button variant={"contained"} color={'error'}
                            onClick={() => statusRequest(currentRequest?.id!, StatusEnum.REJECTED)}>Отклонить
                    </Button>
                    : ''}
            </DialogActions>
        </Dialog>

        <Popup popup={[popup, setPopup]}/>
    </>);
}