import {TabPanel} from "@mui/lab";
import {CompanyType} from "../../types/CompanyType";
import {REST} from "../../REST";
import React, {ReactElement, useEffect, useState} from "react";
import {Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {Popup, PopupType} from "../../Popup";

export function TabAdminCompany(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;
    const [companies, setCompanies] = useState<CompanyType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentCompany, setCurrentCompany] = useState<CompanyType | null>(null);

    useEffect(() => {
        REST.getCompanies().then((c: CompanyType[]) => {
            setCompanies(c)
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>
            <Button variant="contained" disabled={loading} onClick={() => setShowModal(true)}>Подключить
                организацию</Button>

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ИНН</TableCell>
                            <TableCell>Название организации</TableCell>
                            <TableCell>Руководитель</TableCell>
                            <TableCell>Адрес</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading
                            ? <TableRow>
                                <TableCell colSpan={4}><IconButton
                                    loading><HourglassBottomIcon/></IconButton></TableCell>
                            </TableRow>
                            : companies.length === 0
                                ? <TableRow>
                                    <TableCell colSpan={4}>Нет подключенных организаций</TableCell>
                                </TableRow>
                                : companies.map((c: CompanyType) => (
                                    <TableRow key={c.inn} sx={{
                                        '&:last-child td, &:last-child th': {border: 0},
                                        textDecoration: c.is_closed ? 'line-through red' : 'none',
                                    }}>
                                        <TableCell>{c.inn}</TableCell>
                                        <TableCell>{c.name}</TableCell>
                                        <TableCell>{c.director}</TableCell>
                                        <TableCell>{c.address ?? ""}</TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </TabPanel>
        <Popup popup={[popup, setPopup]}/>
    </>);
}

/*
<RegisterCompanyModal visibleState={[showModal, setShowModal]}
                                  companyState={[currentCompany, setCurrentCompany]}/>
 */