import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {Popup, PopupType} from "../../Popup";
import {DogovorType} from "../../types/DogovorType";
import {Utils} from "../../utils/Utils";
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {TabPanel} from "@mui/lab";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export function TabAdminContract(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;
    const [contracts, setContracts] = useState<DogovorType[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentContract, setCurrentContract] = useState<DogovorType | null>(null);

    useEffect(() => {
        REST.getDogovors().then((d: DogovorType[]) => {
            setContracts(d)
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>

            <Button variant="contained" disabled={loading} onClick={() => setShowModal(true)}>Добавить договор</Button>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>№ договора</TableCell>
                            <TableCell>Дата начала</TableCell>
                            <TableCell>Дата окончания</TableCell>
                            <TableCell>Заказчик</TableCell>
                            <TableCell>Исполнитель</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading
                            ? <TableRow>
                                <TableCell colSpan={4}><IconButton
                                    loading><HourglassBottomIcon/></IconButton></TableCell>
                            </TableRow>
                            : contracts.length === 0
                                ? <TableRow>
                                    <TableCell colSpan={5}>Нет заключенных договоров</TableCell>
                                </TableRow>
                                : contracts.map((d: DogovorType) =>
                                    <TableRow key={d.id}
                                              sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: "pointer",
                                                      textDecoration: Utils.dateDiff(new Date(), new Date(d.end)) < 0 ? 'line-through red' : 'none',}}
                                              onClick={() => {
                                                  setCurrentContract(d);
                                                  setShowModal(true);
                                              }}>
                                        <TableCell>{d.number}</TableCell>
                                        <TableCell>{Utils.formatDate(d.start)}</TableCell>
                                        <TableCell>{Utils.formatDate(d.end)}</TableCell>
                                        <TableCell>{d.customer.name}</TableCell>
                                        <TableCell>{d.executor.name}</TableCell>
                                    </TableRow>
                                )}
                    </TableBody>
                </Table>
            </TableContainer>

        </TabPanel>
        <Popup popup={[popup, setPopup]}/>
    </>);
}
/*
<AddContractModal visibleState={[showModal, setShowModal]}
                           contractState={[currentContract, setCurrentContract]}/>

 */