import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {PassportType} from "../../types/PassportType";
import {Popup, PopupType} from "../../Popup";
import {TabPanel} from "@mui/lab";
import {Button, IconButton, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export function TabPassports(props: any): ReactElement {
    const navigate = useNavigate();
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;
    const [passports, setPassports] = useState<PassportType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        REST.getPassports().then((p: PassportType[]) => {
            setPassports(p);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>
            <Button variant="contained" disabled={loading} onClick={() => navigate('/water/passport')}>Создать
                паспорт</Button>

            <List>
                {loading
                    ? <ListItem><IconButton loading><HourglassBottomIcon/></IconButton></ListItem>
                    : passports.length === 0
                        ? <ListItem disablePadding>Нет паспортов ОТБ ТС</ListItem>
                        : passports.map((p: PassportType) =>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate('/water/passport/' + p.id)}>
                                    <ListItemText primary={"Паспорт № " + p.id}/>
                                </ListItemButton>
                            </ListItem>
                        )}
            </List>

        </TabPanel>
        <Popup popup={[popup, setPopup]}/>
    </>);
}
