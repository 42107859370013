import React, {ReactElement, useState} from "react";
import {Avatar, Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {Logout, PrivacyTip} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {REST} from "../REST";
import {Utils} from "../utils/Utils";
import {EmployeeType} from "../types/EmployeeType";

export default function ProfileMenu(props: any): ReactElement {
    const me = props.me as EmployeeType;
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = props.anchor;
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuItem onClick={() => navigate('/me')} disabled>
                <Avatar src={process.env.REACT_APP_BASE + '/api/user/' + me.id + '/avatar'}/> {Utils.shortFio(me.fio)}
            </MenuItem>

            {me.login === me.company.inn.toString()
                ? <MenuItem onClick={() => navigate('/legal')}>
                    <ListItemIcon><PrivacyTip/></ListItemIcon>Правовая информация
                </MenuItem>
                : ''
            }
            <Divider/>
            <MenuItem onClick={() => {
                REST.logout();
            }}>
                <ListItemIcon><Logout/></ListItemIcon>
                Выйти
            </MenuItem>
        </Menu>

    </>);
}
