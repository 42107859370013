import {ReactElement} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {EmployeeType} from "./types/EmployeeType";
import {LoginPage} from "./LoginPage";
import {Main} from "./ecosystem/Main";
import {NotFound} from "./NotFound";
import {AdminIndex} from "./ecosystem/admin/AdminIndex";
import {WaterIndex} from "./ecosystem/water/WaterIndex";
import {WaterContractObjectPage} from "./ecosystem/water/WaterContractObjectPage";
import { WaterPublicPage } from "./ecosystem/water/WaterPublicPage";
import { LegalPage } from "./ecosystem/LegalPage";


export default function App(): ReactElement {
    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');

    if (Object.keys(me).length === 0) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/water/public" element={<WaterPublicPage/>}/>
                    <Route path="*" element={<Navigate to={"/login"}/>}/>
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main me={me}/>}/>

                    <Route path="/water" element={<WaterIndex me={me}/>}/>
                    <Route path="/water/public" element={<WaterPublicPage/>}/>
                    <Route path="/water/contract/object/:id" element={<WaterContractObjectPage me={me}/>}/>

                    <Route path="/admin" element={<AdminIndex me={me}/>}/>
                    <Route path="/legal" element={<LegalPage me={me}/>}/>
                    <Route path="*" element={<NotFound me={me}/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

/*
            <Route path="/profile" element={<ProfilePage me={me}/>}/>

            <Route path="/water/request/:id" element={<RequestPage me={me}/>}/>
            <Route path="/water/passport" element={<PassportPage me={me}/>}/>

            <Route path="/road" element={<RoadIndex me={me}/>}/>
            <Route path="/road/contract/new" element={<NewContractPage me={me}/>}/>
            <Route path="/road/contract/:id" element={<RoadContractPage me={me}/>}/>
            <Route path="/road/contract/:id/:dkha" element={<ContractDKHAPage me={me}/>}/>

 */