import React, {ReactElement, useState} from "react";
import {Popup, PopupType} from "../../Popup";
import {IconButton, Table, TableContainer} from "@mui/material";
import {TabPanel} from "@mui/lab";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export function TabAnalytics(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>
            <TableContainer>
                <Table size="small">
                    <IconButton loading><HourglassBottomIcon/></IconButton>
                </Table>
            </TableContainer>
        </TabPanel>
        <Popup popup={[popup, setPopup]}/>
    </>);
}
