import React, {ChangeEvent, ReactElement, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {REST} from "../../REST";
import {WaterPublicType} from "../../types/WaterPublicType";
import {NPDInput} from "../../component/NPDInput";
import {Popup, PopupType} from "../../Popup";
import { Utils } from "../../utils/Utils";

export function WaterPublicPage(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);
    //const {id} = useParams<string>();
    const [id, setId] = useState<string>();
    const [info, setInfo] = useState<WaterPublicType>();
    const [disabled, setDisabled] = useState<boolean>(true);

    const requestInfo = () => {
        REST.getWaterPublic(id!).then(i => {
            setInfo(i);
        }).catch((error: Error) => {
            setPopup({title: 'Ошибка получения информации', message: error.message});
        });
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9]{5}-[0-9]{2}-[0-9]{3}$/.test(event.target.value ?? '')) {
            setDisabled(false);
            setId(event.target.value.replace('', ''));
        } else {
            setDisabled(true);
        }
    }

    return (<>
            <Dialog open={true} sx={{backgroundColor: 'rgb(2, 136, 209)'}} slots={{backdrop: 'span'}} component={'form'}>
                <DialogTitle>
                    <Stack direction={"row"} spacing={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
                        <Typography component={"h5"} variant={"h5"}
                                    sx={{textAlign: "center"}}>Публичная страница МОРРЕЧФЛОТ</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>

                    {id === undefined || id === null || id.length === 0 || info === undefined
                        ? <TextField id={"npd"} name={"npd"} label={"Номер процедуры досмотра"} fullWidth required
                                     slotProps={{
                                         input: {
                                             inputComponent: NPDInput as any,
                                         },
                                         inputLabel: {shrink: true},
                                     }}
                                     placeholder={'00000-00-000'}
                                     sx={{mt: 1}}
                                     onChange={handleOnChange}
                                     onInput={handleOnChange}

                        />
                        : <>
                            <TextField sx={{mt: 1}} label={"Номер процедуры досмотра"} fullWidth disabled defaultValue={info?.id} slotProps={{inputLabel: {shrink: true},}}/>
                            <TextField sx={{mt: 2}} label={"Объект транспортной инфраструктуры"} fullWidth disabled defaultValue={"-"} slotProps={{inputLabel: {shrink: true},}}/>
                            <TextField sx={{mt: 2}} label={"Транспортное средство"} fullWidth disabled defaultValue={info?.otb} slotProps={{inputLabel: {shrink: true},}}/>
                            <TextField sx={{mt: 2}} label={"Подразделение транспортной безопасности"} fullWidth disabled defaultValue={info?.executor} slotProps={{inputLabel: {shrink: true},}}/>
                            <TextField sx={{mt: 2}} label={"Работник досмотра"}fullWidth disabled defaultValue={info?.final_executor} slotProps={{inputLabel: {shrink: true},}}/>
                            <TextField sx={{mt: 2}} label={"Груз"}  fullWidth disabled defaultValue={info?.cargo} slotProps={{inputLabel: {shrink: true},}}/>
                            <TextField sx={{mt: 2}} label={"Время погрузки"} fullWidth disabled defaultValue={Utils.formatDate(info?.startloadtime) + " - " + Utils.formatDate(info?.endloadtime)} slotProps={{inputLabel: {shrink: true},}}/>
                            <TextField sx={{mt: 2}} label={"При осуществлении досмотра обнаружено (не обнаружено)"} fullWidth disabled defaultValue={"-"} slotProps={{inputLabel: {shrink: true},}}/>
                        </>
                    }

                </DialogContent>
                {id === undefined || id === null || id.length === 0 || info === undefined
                    ? <DialogActions sx={{mr: 2, ml: 2, mb: 2}}>
                        <Button loading={false} size="large" type="submit" variant="contained" fullWidth
                                disabled={disabled}
                                onClick={(e) => {e.preventDefault();requestInfo();}}>Показать информацию</Button>
                    </DialogActions>
                    : ''
                }
            </Dialog>
            <Popup popup={[popup, setPopup]}/>
        </>
    )
}
