import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {FileType} from "../../types/FileType";
import {Popup, PopupType} from "../../Popup";
import {IconButton, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {TabPanel} from "@mui/lab";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export function TabDocuments(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;

    const [documents, setDocuments] = useState<FileType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        REST.getFileByType("water_docs").then((d: FileType[]) => {
            setDocuments(d);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>

            <List>
                {loading
                    ? <ListItem><IconButton loading><HourglassBottomIcon/></IconButton></ListItem>
                    : documents.length === 0
                        ? <ListItem disablePadding>Нет доступных документов</ListItem>
                        : documents.map((d: FileType) => <ListItem disablePadding>
                                <ListItemButton onClick={() => REST.download(d)}>
                                    <ListItemText primary={d.name}/>
                                </ListItemButton>
                            </ListItem>
                        )}
            </List>

        </TabPanel>
        <Popup popup={[popup, setPopup]}/>
    </>);
}
