import {EmployeeType} from "../types/EmployeeType";

export class Utils {
    private static dateTimeFormatter(date: Date, pattern: string): string {
        return pattern.replace(/\b(\w)(\1)*\b/g, (match) => {
            switch (match[0]) {
                case 'Y':
                    return `${date.getFullYear()}`;
                case 'M':
                    return `${date.getMonth() + 1}`.padStart(match.length, '0');
                case 'D':
                    return `${date.getDate()}`.padStart(match.length, '0');
                case 'h':
                    return `${date.getHours()}`.padStart(match.length, '0');
                case 'm':
                    return `${date.getMinutes()}`.padStart(match.length, '0');
                case 'a':
                    return date.getHours() < 12 ? 'am' : 'pm';
            }
            return match;
        })
    }

    public static hasRole(role: string): boolean {
        const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
        for (let i = 0; i < me.roles.length; i++) {
            if (me.roles[i].code === role) {
                return true;
            }
        }
        return false;
    }

    public static shortFio(fio: string): string {
        let ff: string[] = fio.split(' ');
        if(ff.length > 1)
            return ff[0] + ' ' + ff.slice(1).map(f => f.substring(0, 1)).join('. ') + '.';
        return fio;
    }

    public static formatDateTime(dateTime: string | undefined | null): string {
        if(dateTime === undefined || dateTime === null) return ""
        return Utils.dateTimeFormatter(new Date(Date.parse(dateTime)), "DD.MM.Y hh:mm");
    }

    public static formatDate(dateTime: string | undefined | null): string {
        if(dateTime === undefined || dateTime === null) return ""
        return Utils.dateTimeFormatter(new Date(Date.parse(dateTime)), "DD.MM.Y");
    }

    public static formatTime(dateTime: string | undefined | null): string {
        if(dateTime === undefined || dateTime === null) return ""
        return Utils.dateTimeFormatter(new Date(Date.parse(dateTime)), "hh:mm");
    }

    public static dateDiff(date1: Date, date2: Date): number {
        return date2.getTime() - date1.getTime();
    }
}