import React, {ReactElement, useEffect, useState} from "react";
import {Alert, AlertTitle, Box, Collapse, IconButton, Typography, Zoom} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

export function Popup(props: any) : ReactElement {
    const [popup, setPopup] = props.popup as [(PopupType | null), React.Dispatch<React.SetStateAction<PopupType | null>>];
    const theme = useTheme();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setPopup(null);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, []);

    if(!popup) {
        return(<></>);
    }

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (<>
        <Zoom key={'popup'} in={popup !== null} timeout={transitionDuration} style={{transitionDelay: `${transitionDuration.exit}ms`}}
              unmountOnExit>
            <Box sx={{position: 'absolute', bottom: 10, left: 10, 'z-index': 99999}}>
                <Collapse in={popup !== null}>
                    <Alert severity={(popup!.type ?? 'error')} action={
                        <IconButton
                            aria-label="Закрыть"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setPopup(null);
                            }}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }>
                        <AlertTitle>{popup!.title}</AlertTitle>
                        {popup!.message.split("\n").map((m: string, i: number) =>
                            <Typography key={i} variant={'caption'} component={'div'}>{m}</Typography>
                        )}
                    </Alert>
                </Collapse>
            </Box>
        </Zoom>
    </>)
}

export type PopupType = {
    type?: 'error' | 'success' | 'info' | 'warning',
    title: string,
    message: string
}