import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {RequestType, Status} from "../../types/RequestType";
import {EmployeeType} from "../../types/EmployeeType";
import {Utils} from "../../utils/Utils";
import {Popup, PopupType} from "../../Popup";
import {TabPanel} from "@mui/lab";
import {
    Button, Chip,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import {RequestModal} from "./modal/RequestModal";

export function TabInRequests(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);
    const value = props.value as string;
    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');

    const [requests, setRequests] = useState<RequestType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentRequest, setCurrentRequest] = useState<RequestType>();

    useEffect(() => {
        REST.getRequest("in").then((r: RequestType[]) => {
            setRequests(r);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'center'}>Статус</TableCell>
                            <TableCell>№, дата</TableCell>
                            <TableCell>Судно</TableCell>
                            <TableCell>Приход в порт (причал)</TableCell>
                            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>Отход в порт</TableCell>
                            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>Цель захода / место</TableCell>
                            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>Груз / кол-во (т./куб.м)</TableCell>
                            <TableCell>Ориентировочная дата и время прибытия</TableCell>
                            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>Ориентировочная дата погрузки</TableCell>
                            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>Контакты судна</TableCell>
                            <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>Контакты оператора</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading
                            ? <TableRow>
                                <TableCell colSpan={11} sx={{display: {xs: 'none', md: 'table-cell'}}}><CircularProgress/></TableCell>
                                <TableCell colSpan={5} sx={{display: {xs: 'table-cell', md: 'none'}}}><CircularProgress/></TableCell>
                            </TableRow>
                            : requests.length === 0
                                ? <TableRow>
                                    <TableCell colSpan={11} sx={{display: {xs: 'none', md: 'table-cell'}}}>Нет входящих заявок</TableCell>
                                    <TableCell colSpan={5} sx={{display: {xs: 'table-cell', md: 'none'}}}>Нет входящих заявок</TableCell>
                                </TableRow>
                                : requests.map((r: RequestType) =>
                                    <TableRow key={r.id} hover={true} sx={{cursor: "pointer",}}
                                              onClick={() => {
                                                  setCurrentRequest(r);
                                                  setShowModal(true);
                                              }}>
                                        <TableCell align={'center'}><Chip label={Status[r.status].name} color={Status[r.status].color}/></TableCell>
                                        <TableCell>{'№' + r.id + ' от ' + Utils.formatDate(r.date)}</TableCell>
                                        <TableCell>{r.otb}</TableCell>
                                        <TableCell>{r.inport}</TableCell>
                                        <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>{r.outport}</TableCell>
                                        <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>{r.target} / {r.place}</TableCell>
                                        <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>{r.cargo} / {r.count}</TableCell>
                                        <TableCell>{Utils.formatDateTime(r.indatetime)}</TableCell>
                                        <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>{Utils.formatDate(r.startloadtime)} - {Utils.formatDate(r.endloadtime)}</TableCell>
                                        <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>{r.contact}</TableCell>
                                        <TableCell sx={{display: {xs: 'none', md: 'table-cell'}}}>{r.contactoperator}</TableCell>
                                    </TableRow>
                                )}

                    </TableBody>
                </Table>
            </TableContainer>
        </TabPanel>
        <RequestModal show={[showModal, setShowModal]} currentRequestState={[currentRequest, setCurrentRequest]}/>

        <Popup popup={[popup, setPopup]}/>
    </>);
}
