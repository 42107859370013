import React, {Dispatch, ReactElement, SetStateAction} from "react";
import {EmployeeType} from "../../../types/EmployeeType";
import {Utils} from "../../../utils/Utils";
import {ContractObjectType, ContractType} from "../../../types/ContractType";
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl,
    InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, OutlinedInput
} from "@mui/material";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import {useNavigate} from "react-router-dom";

export function ContractModal(props: any): ReactElement {
    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
    const navigate = useNavigate();
    const readOnly: boolean = props.readOnly;
    const [open, setOpen] = props.show as [boolean, Dispatch<SetStateAction<boolean>>];
    const [currentContract, setCurrentContract] = props.current as [ContractType | null, Dispatch<SetStateAction<ContractType | null>>];
    //const [subordinates, setSubordinates] = useState<EmployeeType[]>([]);
    //const [blocks, setBlocks] = useState<RequestBlockType[]>([]);
    //const [newBlock, setNewBlock] = useState<RequestBlockType | null>(null);
    //const [isBlockSaved, setBlockSaved] = useState<boolean>(false);

    //useEffect(() => {
    //REST.getSubordinates().then(s => {
    //setSubordinates(s);
    //});
    //}, []);

    //useEffect(() => {
    //setBlocks(currentRequest?.blocks ?? []);
    //}, [currentRequest]);

    const handleClose = () => {
        setCurrentContract(null);
        setOpen(false);
    }

    //const statusRequest = (id: bigint, status: number) => {
    //REST.setRequestStatus(id, status).then((r: RequestType) => {
    //handleClose();
    ////TODO: update only one record in table
    //})
    //}

    //const changeFinalExecutor = (e: ChangeEvent<HTMLSelectElement>) => {
    //const finalExecutorID = parseInt(e.target.value);
    //const payload = {
    //id: currentRequest?.id,
    //feid: finalExecutorID
    //}
    //REST.changeFinalExecutor(payload);
    //}

    /*const uploadDocument = (r: RequestType | undefined) => {
        let file: HTMLInputElement = document.getElementById("document") as HTMLInputElement;
        let fileType: HTMLInputElement = document.getElementById("doctype") as HTMLInputElement;
        if (file.files!.length === 0)
            return
        let formData = new FormData();

        formData.append("data", file.files!.item(0)!);
        formData.append("name", file.files!.item(0)!.name);
        formData.append("type", fileType.value);
        formData.append("rid", r!.id.toString());

        REST.uploadFile(formData).then((f: FileType) => {
            file.value = '';
            if (currentRequest === undefined)
                currentRequest.files = [f];
            else
                currentRequest.files?.push(f);
        });
        //TODO: add spinner while upload
        //TODO: add error button on fail
    };*/

    if (!open) {
        return (<></>);
    }

    //const existsBlockType = (type: number) => {
    //return ((blocks.filter((b) => b.type === type).length ?? 0) > 0);
    /*if((blocks.length??0) > 0) {
        return (blocks.at((blocks.length) - 1)!.type) === type;
    }
    return false;*/
    //console.log(type + ":" + ret);
    //}

    /*const addNewBlock = (type: number) => {
        setNewBlock({
            id: undefined,
            rid: currentRequest?.id!,
            type: type,
            result: null,
            violations: null,
            prohibited_objects: null,
            cargo: null,
            photo_ts: null,
            photo_holds: null,
            photos: null,
            ship_role: null,
            lading_bill: null,
            manifesto: null,
            inspection_report: null,
            sealing_act: null,
            created_at: null,
            start_at: null,
            finish_at: null
        });
        setBlockSaved(false);
    }*/

    return (<Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{"Контракт №" + currentContract?.number + " от " + Utils.formatDate(currentContract?.date)}</DialogTitle>
        <DialogContent dividers>

            <FormControl fullWidth sx={{mb: 2}} disabled>
                <InputLabel htmlFor={'customer'}>Заказчик</InputLabel>
                <OutlinedInput id={'customer'} label={"Заказчик"}
                               value={currentContract!.customer.name + ' (ИНН ' + currentContract!.customer.inn + ')'}/>
            </FormControl>
            <FormControl fullWidth sx={{mb: 2}} disabled>
                <InputLabel htmlFor={'executor'}>Исполнитель</InputLabel>
                <OutlinedInput id={'executor'} label={"Исполнитель"}
                               value={currentContract!.executor.name + ' (ИНН ' + currentContract!.executor.inn + ')'}/>
            </FormControl>
            <FormControl fullWidth sx={{mb: 2}} disabled>
                <InputLabel htmlFor={'start-finish'}>Срок контракта</InputLabel>
                <OutlinedInput id={'start-finish'} label={"Срок контракта"}
                               value={Utils.formatDate(currentContract!.start) + ' - ' + Utils.formatDate(currentContract!.finish)}
                />
            </FormControl>
            <FormControl fullWidth sx={{mb: 2}} disabled>
                <InputLabel htmlFor={'executor'}>Место оказания услуг</InputLabel>
                <OutlinedInput id={'executor'} label={"Место оказания услуг"} value={currentContract!.region.name}/>
            </FormControl>

            <Divider/>

            <List subheader={<ListSubheader>Список объектов:</ListSubheader>}>
                {currentContract!.objects.length === 0
                    ? <ListItem disablePadding>Нет объектов</ListItem>
                    : currentContract!.objects.map((co: ContractObjectType) =>
                        <ListItem disablePadding key={co.id}>
                            <ListItemButton onClick={() => navigate('/water/contract/object/' + co.id)}>
                                <ListItemIcon><DirectionsBoatIcon /></ListItemIcon>
                                <ListItemText primary={co.name}/>
                            </ListItemButton>
                        </ListItem>
                    )
                }
            </List>

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color={'error'}>Отмена</Button>
            {/*<div className="Button Default Disabled" style={{marginLeft: "10px"}}
                               onClick={() => alert('Будет произведена отправка в ЕГИС ОТБ')}>
                            <FontAwesomeIcon icon={faEnvelope}/>&nbsp;Отправить в ЕГИС ОТБ
                        </div>*/
            }
            {/*!readOnly && true ?
                        <div className="Button Primary" style={{marginLeft: "10px"}} onClick={() => {}}>В рассмотрение</div>
                        : ""*/}
        </DialogActions>
    </Dialog>);

}