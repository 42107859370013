import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {NotificationType} from "../../types/NotificationType";
import {Popup, PopupType} from "../../Popup";
import {Button, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {TabPanel} from "@mui/lab";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export function TabOutNotifications(props: any): ReactElement {
    const [popup, setPopup] = useState<PopupType | null>(null);

    const value = props.value as string;
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [currentNotification, setCurrentNotification] = useState<NotificationType>();
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        REST.getNotifications("out").then((n: NotificationType[]) => {
            setNotifications(n);
            setLoading(false);
        }).catch((error: Error) => {
            setLoading(false);
            setPopup({title: 'Ошибка получения данных', message: error.message});
        });
    }, []);

    return (<>
        <TabPanel value={value} sx={{justifyItems: 'center'}}>
            <Button variant="contained" disabled={loading} onClick={() => setShowModal(true)}>Создать
                уведомление</Button>

            <List>
                {loading
                    ? <ListItem><CircularProgress/></ListItem>
                    : notifications.length === 0
                        ? <ListItem disablePadding>Нет исходящих уведомлений</ListItem>
                        : notifications.map((n: NotificationType) =>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => {
                                    setCurrentNotification(n);
                                    setShowModal(true);
                                }}>
                                    <ListItemText primary={'Уведомление №' + n.id}/>
                                </ListItemButton>
                            </ListItem>
                        )}
            </List>

        </TabPanel>
        <Popup popup={[popup, setPopup]}/>
    </>);
}

/*
<NotificationModal visibleState={[showModal, setShowModal]}
                                  notificationState={[currentNotification, setCurrentNotification]}/>
 */