import {ReactElement, SetStateAction, Dispatch, useState} from "react";
import {RequestBlockType} from "../../../types/RequestBlockType";
import {REST} from "../../../REST";
import {Utils} from "../../../utils/Utils";
import {
    Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, FormControl, IconButton,
    InputLabel, List, ListItem,
    ListItemButton, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, Stack, Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import PhotoIcon from "@mui/icons-material/Photo";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {DropzoneArea} from "mui-file-dropzone";
import {PopupType} from "../../../Popup";

export function RequestBlock(props: any): ReactElement {
    const [popup, setPopup] = props.popup as [(PopupType | null), Dispatch<SetStateAction<PopupType | null>>];

    const block = props.block as RequestBlockType;
    let isNew: boolean = block === null || block.id === undefined;
    const requestNumber = props.requestNumber as number;
    const readOnly: boolean = props.readOnly ?? false;
    const [isSaved, setIsSaved] = props.saved as [boolean, Dispatch<SetStateAction<boolean>>];
    const [blocks, setBlocks] = props.blocks as [RequestBlockType[], Dispatch<SetStateAction<RequestBlockType[]>>];

    const nowDate = new Date();
    const now = nowDate.getFullYear().toString().padStart(2, '0') +
        "-" + (nowDate.getMonth() + 1).toString().padStart(2, '0') +
        "-" + nowDate.getDate().toString().padStart(2, '0') +
        "T" + nowDate.getHours().toString().padStart(2, '0') +
        ":" + nowDate.getMinutes().toString().padStart(2, '0');

    const [startAt, setStartAt] = useState<string | null>(null);
    const [finishAt, setFinishAt] = useState<string | null>(null);
    const [violations, setViolations] = useState<string | null>(null);
    const [result, setResult] = useState<string | null>(null);
    const [prohibitedObjects, setProhibitedObjects] = useState<string | null>(null);
    const [uploadPhotoTsFiles, setUploadPhotoTsFiles] = useState<File[]>([]);
    const [uploadPhotoHoldsFiles, setUploadPhotoHoldsFiles] = useState<File[]>([]);
    const [cargo, setCargo] = useState<string | null>(null);
    const [uploadPhotoFiles, setUploadPhotoFiles] = useState<File[]>([]);
    const [uploadShipRoleFiles, setUploadShipRoleFiles] = useState<File[]>([]);
    const [uploadLadingBillFiles, setUploadLadingBillFiles] = useState<File[]>([]);
    const [uploadManifestoFiles, setUploadManifestoFiles] = useState<File[]>([]);
    const [uploadInspectionReportFiles, setUploadInspectionReportFiles] = useState<File[]>([]);
    const [uploadSealingActFiles, setUploadSealingActFiles] = useState<File[]>([]);


    const save = () => {
        let formData = new FormData();

        formData.append("rid", block.rid.toString());
        formData.append("type", block.type.toString());
        if (startAt)
            formData.append("start_at", startAt);
        if (finishAt)
            formData.append("finish_at", finishAt);
        if (violations)
            formData.append("violations", violations);
        if (result)
            formData.append("result", result);
        if (prohibitedObjects)
            formData.append("prohibited_objects", prohibitedObjects);
        if (cargo)
            formData.append("cargo", cargo);

        for (let i = 0; i < uploadPhotoTsFiles.length; i++) {
            formData.append("photo_ts", uploadPhotoTsFiles.at(i)!);
        }
        for (let i = 0; i < uploadPhotoHoldsFiles.length; i++) {
            formData.append("photo_holds", uploadPhotoHoldsFiles.at(i)!);
        }
        for (let i = 0; i < uploadPhotoFiles.length; i++) {
            formData.append("photos", uploadPhotoFiles.at(i)!);
        }
        for (let i = 0; i < uploadShipRoleFiles.length; i++) {
            formData.append("ship_role", uploadShipRoleFiles.at(i)!);
        }
        for (let i = 0; i < uploadLadingBillFiles.length; i++) {
            formData.append("lading_bill", uploadLadingBillFiles.at(i)!);
        }
        for (let i = 0; i < uploadManifestoFiles.length; i++) {
            formData.append("manifesto", uploadManifestoFiles.at(i)!);
        }
        for (let i = 0; i < uploadInspectionReportFiles.length; i++) {
            formData.append("inspection_report", uploadInspectionReportFiles.at(i)!);
        }
        for (let i = 0; i < uploadSealingActFiles.length; i++) {
            formData.append("sealing_act", uploadSealingActFiles.at(i)!);
        }

        REST.newRequestBlock(formData).then((rb: RequestBlockType) => {
            //console.log(rb);
            setBlocks((prev) => [...prev, rb]);
            setIsSaved(true);
            //setIsNew(null);
        }).catch((error: Error) => {
            setPopup({title: 'Ошибка создания блока', message: error.message});
        })
    };

    switch (block.type) {
        case 1:
            return Utils.hasRole("water_ptb_request") ? (
                    <Accordion defaultExpanded={isNew} sx={{width: '100%'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography><b>Отчет о начале
                                досмотра</b>{isNew ? '' : (" от " + Utils.formatDate(block.created_at))}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                                <FormControl fullWidth disabled={!isNew}>
                                    <InputLabel htmlFor={'start_at'}>Дата начала осмотра</InputLabel>
                                    <OutlinedInput id={'start_at'} name={"start_at"} label={"Дата начала осмотра"}
                                                   type={isNew ? "datetime-local" : "text"}
                                                   defaultValue={isNew ? now : Utils.formatDate(block.start_at)}/>
                                </FormControl>
                                <FormControl fullWidth disabled={true}>
                                    <InputLabel htmlFor={'finish_at'}>Дата окончания осмотра</InputLabel>
                                    <OutlinedInput id={'finish_at'} name={"finish_at"} label={"Дата окончания осмотра"}
                                                   defaultValue={'не применимо'} sx={{cursor: 'not-allowed'}}/>
                                </FormControl>
                            </Stack>
                            <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                                <FormControl fullWidth disabled={!isNew}>
                                    <InputLabel htmlFor={'result'}>Результат досмотра грузовых отсеков (трюмов)
                                        ТС</InputLabel>
                                    <Select defaultValue={block.result ?? 'досмотрено'} labelId="result"
                                            name={"result"}
                                            label={'Результат досмотра грузовых отсеков (трюмов) ТС'}>
                                        <MenuItem value={'досмотрено'}>Досмотрено</MenuItem>
                                        <MenuItem value={'не досмотрено'}>Не досмотрено</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth disabled={!isNew}>
                                    <InputLabel htmlFor={'prohibited_objects'}>Запрещенные объекты</InputLabel>
                                    <Select defaultValue={block.prohibited_objects ?? 'не обнаружены'} labelId="result"
                                            name={"prohibited_objects"}
                                            label={'Запрещенные объекты'}>
                                        <MenuItem value={'не обнаружены'}>Не обнаружены</MenuItem>
                                        <MenuItem value={'обнаружены'}>Обнаружены</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>
                            <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                                <InputLabel htmlFor={'violations'}>Описание выявленных нарушений</InputLabel>
                                <OutlinedInput id={'violations'} name={"violations"} label={"Описание выявленных нарушений"}
                                               defaultValue={block.violations ?? 'не обнаружено'} multiline={true}
                                               inputProps={{minRows: 2}}/>
                            </FormControl>
                            <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                                <InputLabel htmlFor={'photo_ts'}>Фото ТС</InputLabel>
                                {isNew
                                    ? <DropzoneArea
                                        maxFileSize={1000000000}
                                        showAlerts={false}
                                        acceptedFiles={['image/*']}
                                        showFileNames={true}
                                        showPreviewsInDropzone={true}
                                        Icon={AddPhotoAlternateIcon}
                                        dropzoneText={"Фотоматериалы"}
                                        onChange={(files) => setUploadPhotoTsFiles(files)}/>
                                    : <List>{block.photo_ts?.map(p => <ListItem disablePadding key={p.id}>
                                            <ListItemButton onClick={() => REST.download(p)}>
                                                <ListItemIcon><PhotoIcon/></ListItemIcon>
                                                <ListItemText primary={p.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    </List>
                                }
                            </FormControl>
                            <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                                <InputLabel htmlFor={'photo_holds'}>Фото трюмов</InputLabel>
                                {isNew
                                    ? <DropzoneArea
                                        maxFileSize={1000000000}
                                        showAlerts={false}
                                        acceptedFiles={['image/*']}
                                        showFileNames={true}
                                        showPreviewsInDropzone={true}
                                        Icon={AddPhotoAlternateIcon}
                                        dropzoneText={"Фотоматериалы"}
                                        onChange={(files) => setUploadPhotoHoldsFiles(files)}/>
                                    : <List>{block.photo_holds?.map(p => <ListItem disablePadding key={p.id}>
                                            <ListItemButton onClick={() => REST.download(p)}>
                                                <ListItemIcon><PhotoIcon/></ListItemIcon>
                                                <ListItemText primary={p.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    </List>
                                }
                            </FormControl>
                        </AccordionDetails>
                        {isNew ? <AccordionActions>
                            <Button variant="contained" color={"primary"} onClick={() => save()}>Сохранить и
                                подписать</Button>
                        </AccordionActions> : ''}
                    </Accordion>
                )
                : <></>
        case 2:
            return (<></>);
        /*return (
            <Accordion showBody={isNew}
                       title={<><b>Отчет о преддосмотре</b>{isNew ? "" : (" от " + block.formattedCreatedAt)}
                           {isNew ? "" : <FontAwesomeIcon
                               icon={faDownload} style={{paddingLeft: "5px"}}/>}</>}>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Дата начала преддосмотра</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={start_at}/>
                        : <div className="form-control">{block.formattedStartAt}</div>
                    }
                    <span className="input-group-text">Дата окончания преддосмотра</span>
                    <div className="form-control" style={{cursor: "not-allowed"}}>не применимо</div>
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Проверка документов и согласование с капитаном судна</span>
                    <div className="form-control">????????</div>
                </div>

                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">ФИО участвующих работников</span>
                    <textarea className="form-control" ref={participants} readOnly={readOnly}
                              defaultValue={block.participants ?? ''}></textarea>
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Описание предварительных действий</span>
                    <textarea className="form-control" ref={actions} readOnly={readOnly} defaultValue={block.actions ?? ''}></textarea>
                </div>
                {isNew ? <div className={"Button Primary"} onClick={() => save()}>Сохранить и подписать</div> : ""}
            </Accordion>
        )*/
        case 3:
            return Utils.hasRole("water_ptb_request") ? (
                    <Accordion defaultExpanded={isNew} sx={{width: '100%'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography><b>Отчет о досмотре</b>{isNew ? '' : (" от " + Utils.formatDate(block.created_at))}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                                <FormControl fullWidth disabled={!isNew}>
                                    <InputLabel htmlFor={'start_at'}>Дата начала осмотра</InputLabel>
                                    <OutlinedInput id={'start_at'} name={"start_at"} label={"Дата начала осмотра"}
                                                   type={isNew ? "datetime-local" : "text"}
                                                   defaultValue={isNew ? now : Utils.formatDate(block.start_at)}/>
                                </FormControl>
                                <FormControl fullWidth disabled={true}>
                                    <InputLabel htmlFor={'finish_at'}>Дата окончания осмотра</InputLabel>
                                    <OutlinedInput id={'finish_at'} name={"finish_at"} label={"Дата окончания осмотра"}
                                                   defaultValue={'не применимо'} sx={{cursor: 'not-allowed'}}/>
                                </FormControl>
                            </Stack>
                            <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                                <InputLabel htmlFor={'cargo'}>Количество досмотренного груза (т/м<sup>3</sup>)</InputLabel>
                                <OutlinedInput id={'cargo'} name={"cargo"} label={"Количество досмотренного груза (т/м3)"}
                                               type={'number'} inputProps={{min: 0}}
                                               defaultValue={isNew ? 0 : block.cargo} sx={{cursor: 'not-allowed'}}/>
                            </FormControl>
                            <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                                <InputLabel htmlFor={'violations'}>Описание выявленных нарушений</InputLabel>
                                <OutlinedInput id={'violations'} name={"violations"} label={"Описание выявленных нарушений"}
                                               defaultValue={block.violations ?? 'не обнаружено'} multiline={true}
                                               inputProps={{minRows: 2}}/>
                            </FormControl>
                            <FormControl fullWidth disabled={!isNew}>
                                {isNew
                                    ? <DropzoneArea
                                        maxFileSize={1000000000}
                                        showAlerts={false}
                                        acceptedFiles={['image/*']}
                                        showFileNames={true}
                                        showPreviewsInDropzone={true}
                                        Icon={AddPhotoAlternateIcon}
                                        dropzoneText={"Фотоматериалы"}
                                        onChange={(files) => setUploadPhotoFiles(files)}
                                    />
                                    : <List>{block.photos?.map(p => <ListItem disablePadding key={p.id}>
                                            <ListItemButton onClick={() => REST.download(p)}>
                                                <ListItemIcon><PhotoIcon/></ListItemIcon>
                                                <ListItemText primary={p.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    </List>
                                }
                            </FormControl>
                        </AccordionDetails>
                        {isNew ? <AccordionActions>
                            <Button variant="contained" color={"primary"} onClick={() => save()}>Сохранить и
                                подписать</Button>
                        </AccordionActions> : ''}
                    </Accordion>
                )
                : <></>
        case 4:
            return (<></>)
        /*return (
            <Accordion showBody={isNew}
                       title={<><b>Отчет о пломбировке</b>{isNew ? "" : (" от " + block.formattedCreatedAt)}
                           {isNew ? "" : <FontAwesomeIcon
                               icon={faDownload} style={{paddingLeft: "5px"}}/>}</>}>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Дата начала пломбировки</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={start_at}/>
                        : <div className="form-control">{block.formattedStartAt}</div>
                    }
                    <span className="input-group-text">Дата окончания пломбировки</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={finish_at}/>
                        : <div className="form-control">{block.formattedFinishAt}</div>
                    }
                </div>

                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Дата и время установки пломб</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={sealsDate}/>
                        : <div className="form-control">{block.formattedSealsDate}</div>
                    }
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Места установки пломб</span>
                    <textarea className="form-control" ref={sealsPlaces} readOnly={readOnly}
                              defaultValue={block.seals_places ?? ''}></textarea>
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Номера установленных пломб</span>
                    <textarea className="form-control" ref={sealsNumber} readOnly={readOnly}
                              defaultValue={block.seals_number ?? ''}></textarea>
                </div>

                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Описание процесса пломбировки</span>
                    <textarea className="form-control" ref={actions} readOnly={readOnly} defaultValue={block.actions ?? ''}></textarea>
                </div>
                {isNew ? <div className={"Button Primary"} onClick={() => save()}>Сохранить и подписать</div> : ""}
            </Accordion>
        )*/
        case 5:
            return (
                <Accordion defaultExpanded={isNew} sx={{width: '100%'}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography><b>Отчет о результатах
                            досмотра</b>{isNew ? '' : (" от " + Utils.formatDate(block.created_at))}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack direction={"row"} sx={{mb: 2}} spacing={1}>
                            <FormControl fullWidth disabled={true}>
                                <InputLabel htmlFor={'start_at'}>Дата начала осмотра</InputLabel>
                                <OutlinedInput id={'start_at'} name={"start_at"} label={"Дата начала осмотра"}
                                               defaultValue={'не применимо'} sx={{cursor: 'not-allowed'}}/>
                            </FormControl>
                            <FormControl fullWidth disabled={!isNew}>
                                <InputLabel htmlFor={'finish_at'}>Дата окончания осмотра</InputLabel>
                                <OutlinedInput id={'finish_at'} name={"finish_at"} label={"Дата окончания осмотра"}
                                               type={isNew ? "datetime-local" : "text"}
                                               defaultValue={isNew ? now : Utils.formatDate(block.finish_at)}/>
                            </FormControl>
                        </Stack>
                        <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                            <InputLabel htmlFor={'violations'}>Описание выявленных нарушений и принятых мер</InputLabel>
                            <OutlinedInput id={'violations'} name={"violations"}
                                           label={"Описание выявленных нарушений и принятых мер"}
                                           defaultValue={block.violations ?? 'не обнаружено'} multiline={true}
                                           inputProps={{minRows: 2}}/>
                        </FormControl>
                        <FormControl fullWidth disabled={true} sx={{mb: 2}}>
                            <InputLabel htmlFor={'npd'}>Номер процедуры досмотра</InputLabel>
                            <OutlinedInput id={'npd'} name={"npd"} label={"Номер процедуры досмотра"}
                                           defaultValue={String(requestNumber).padStart(5, '0') +
                                               '-' +
                                               nowDate.getFullYear().toString()[2] + nowDate.getFullYear().toString()[3] +
                                               '-' +
                                               //TODO: Сгенерированный Номер процедуры досмотра
                                               '999'}/>
                        </FormControl>
                        {Utils.hasRole("water_ptb_request")
                            ? <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                                {isNew
                                    ? <DropzoneArea
                                        maxFileSize={1000000000}
                                        showAlerts={false}
                                        showFileNames={true}
                                        showPreviewsInDropzone={true}
                                        Icon={AddPhotoAlternateIcon}
                                        dropzoneText={"Судовая роль"}
                                        onChange={(files) => setUploadShipRoleFiles(files)}
                                    />
                                    : <List>{block.ship_role?.map(p => <ListItem disablePadding key={p.id}>
                                            <ListItemButton onClick={() => REST.download(p)}>
                                                <ListItemIcon><PhotoIcon/></ListItemIcon>
                                                <ListItemText primary={p.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    </List>
                                }
                            </FormControl>
                            : ''
                        }
                        {Utils.hasRole("water_ptb_request")
                            ? <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                                {isNew
                                    ? <DropzoneArea
                                        maxFileSize={1000000000}
                                        showAlerts={false}
                                        showFileNames={true}
                                        showPreviewsInDropzone={true}
                                        Icon={AddPhotoAlternateIcon}
                                        dropzoneText={"Коносамент"}
                                        onChange={(files) => setUploadLadingBillFiles(files)}
                                    />
                                    : <List>{block.lading_bill?.map(p => <ListItem disablePadding key={p.id}>
                                            <ListItemButton onClick={() => REST.download(p)}>
                                                <ListItemIcon><PhotoIcon/></ListItemIcon>
                                                <ListItemText primary={p.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    </List>
                                }
                            </FormControl>
                            : ''
                        }
                        {/*<div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Манифест</span>
                        {!readOnly && isNew
                            ? <input type={"file"} className="form-control" ref={manifesto}/>
                            : <div className="form-control">{block.manifesto?.map(p => <><a href={"#"}
                                                                                            onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                        }
                    </div>
                */}
                        <FormControl fullWidth disabled={!isNew} sx={{mb: 2}}>
                            {isNew
                                ? <DropzoneArea
                                    maxFileSize={1000000000}
                                    showAlerts={false}
                                    showFileNames={true}
                                    showPreviewsInDropzone={true}
                                    Icon={AddPhotoAlternateIcon}
                                    dropzoneText={"Акт досмотра"}
                                    onChange={(files) => setUploadInspectionReportFiles(files)}
                                />
                                : <List>{block.inspection_report?.map(p => <ListItem disablePadding key={p.id}>
                                        <ListItemButton onClick={() => REST.download(p)}>
                                            <ListItemIcon><PhotoIcon/></ListItemIcon>
                                            <ListItemText primary={p.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                </List>
                            }
                        </FormControl>
                        <FormControl fullWidth disabled={!isNew}>
                            {isNew
                                ? <DropzoneArea
                                    maxFileSize={1000000000}
                                    showAlerts={false}
                                    showFileNames={true}
                                    showPreviewsInDropzone={true}
                                    Icon={AddPhotoAlternateIcon}
                                    dropzoneText={"Акт пломбировки / распломбировки"}
                                    onChange={(files) => setUploadSealingActFiles(files)}
                                />
                                : <List>{block.sealing_act?.map(p => <ListItem disablePadding key={p.id}>
                                        <ListItemButton onClick={() => REST.download(p)}>
                                            <ListItemIcon><PhotoIcon/></ListItemIcon>
                                            <ListItemText primary={p.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                </List>
                            }
                        </FormControl>
                    </AccordionDetails>
                    {isNew ? <AccordionActions>
                        <Button variant="contained" color={"primary"} onClick={() => save()}>Сохранить и
                            подписать</Button>
                    </AccordionActions> : ''}
                </Accordion>
            )
        default:
            return (<Accordion defaultExpanded={isNew} sx={{width: '100%'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Неизвестный тип блока</Typography>
                </AccordionSummary>
                <AccordionDetails>
                </AccordionDetails>
            </Accordion>);
    }
}