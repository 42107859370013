import {CountryType} from "./CountryType";
import {CompanyType} from "./CompanyType";
import {RegionType} from "./RegionType";
import {EmployeeType} from "./EmployeeType";
import {RequestBlockType} from "./RequestBlockType";

export type RequestType = {
    id: bigint,
    date: string,//Date
    otb: string,//Наименование судна
    flag: CountryType,//Флаг
    imonumber: bigint,//IMO номер
    registrynumber: string,
    reestrnumber: string,
    mastername: string,//Наименование судовладельца
    contactmaster: string,//Контакты судовладельца
    fromport: string,//Приход из порта
    inport: string,//Приход в порт (страна)
    outport: string,//Отход в порт (страна)
    target: string,//Цель захода
    place: string,//Место захода
    cargo: string,//Груз
    count: string,//Количество груза
    indatetime: string,//Date: Ориентировочная дата и время прибытия ("2025-02-07T10:45:00Z")
    startloadtime: string,//Date: Ориентировочная дата начала погрузки
    endloadtime: string,//Date: Ориентировочная дата окончания погрузки
    contact: string,//Контакты судна
    nameoperator: string,
    addressobject: string,
    contactoperator: string,
    namecargo: string,//Наименоване грузоотправителя
    contactcargo: string,
    nameconsignee: string,//Наименоване грузополучателя
    addressobjectregion: RegionType,
    incountry: CountryType,
    outcountry: CountryType,
    fromcountry: CountryType,
    customer: CompanyType,
    operator: CompanyType,
    executor: CompanyType
    need_operations: number,
    final_executor: EmployeeType,

    blocks: RequestBlockType[] | null,
    //props: any,
    status: number,
}

export const Status : {value: string, className: string, name: string, color: "default" | "primary" | "warning" | "success" | "error" | "info" | "secondary" | undefined}[] = [
    {value: 'NEW', className: '#ffffff', name: 'Новая', color: 'default'},//0 light
    {value: 'REVIEW', className: '#42a5f5', name: 'На рассмотрении', color: 'primary'},//1 primary
    {value: 'PROCESS', className: '#ff9800', name: 'В работе', color: 'warning'},//2 warning
    {value: 'EXECUTED', className: '#4caf50', name: 'Выполнена', color: 'success'},//3 success
    {value: 'REJECTED', className: '#ef5350', name: 'Отклонена', color: 'error'}//4 danger
];

export enum StatusEnum {
    NEW = 0,
    REVIEW = 1,
    PROCESS = 2,
    EXECUTED = 3,
    REJECTED = 4,
}